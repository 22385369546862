/*hero carousel*/
.hero-carousel {
    .owl-item {
        .item {
            position: relative;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            margin-bottom: 24px;
            img{
                border-radius: 12px;
            }
        }
    }
}
@media (max-width: 359.98px) {
    .hero-carousel {
        .owl-item {
            .item {
                margin-bottom: 18px;
            }
        }
    }
}

/*card category carousel*/
.card-category-carousel {
    .owl-stage {
        @include flexbox;
    }
    .owl-item {
        display: block;
        &.active {
            .item {
                position: relative;
                margin-bottom: 10px;
                &.first-item {
                    margin-left: 20px;
                }
            }
        }
        &.cloned {
            .item{
                &.first-item {
                    margin-left: 0;
                }
            }
        }
    }
}

/*App Tour Pointer*/
.app-tour__pointer-btn {
    display: none;
    width: 30px;
    height: 30px;
    padding: 6px;
    border-radius: 50%;
    background-color: #ff5d3b;
    z-index: 3;
    @include align-items(center);
    @include justify-content(center);
    .icon {
        width: 12px;
        height: 12px;
        display: inline-block;
        color: var(--bg-white);
        fill: var(--bg-white);
    }
    &:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
        border-radius: 50%;
        pointer-events: none;
        box-shadow: inset 0 0 1px 1px #ff5d3b;
        -webkit-animation: ring-pulse 2s infinite;
        animation: ring-pulse 2s infinite;
    }
    &.show {
        @include flexbox;
    }
    &:hover {
        .icon {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
    &.is-visited {
        background-color: var(--bg-dark);
        &:after {
            content: none;
        }
        &:hover {
            .icon {
                -webkit-transform: none;
                transform: none;
            }
        }
    }
}
@-webkit-keyframes ring-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes ring-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@media (max-width: 359px) {
    .app-tour__pointer-btn {
        width: 24px;
        height: 24px;
        padding: 4px;
    }
}

/*Modal App Tour*/
.modal__centered {
    &.modal__app-tour {
        padding: 0;
        .modal-content {
            max-width: 100%;
            padding: 0;
            background-color: transparent;
            border-radius: 30px;
        }
    }
}
.app-tour-carousel {
    height: 360px;
    max-width: 100%;
    overflow: hidden;
    .app-tour__item {
        font-family: "Montserrat";
        text-align: center;
        width: 330px;
        height: 300px;
        position: relative;
        margin: 30px auto;
        padding: 15px 20px;
        border-radius: 30px;
        background-color: var(--bg-white);
        .app-tour__icon {
            width: 74px;
            height: 74px;
            margin: 0 auto 16px;
            svg {
                width: 100%;
                height: auto;
                fill: var(--bg-dark);
            }
        }
        .app-tour {
            &__title {
                font-size: 24px;
                font-weight: 800;
                color: var(--text-dark);
                margin-bottom: 10px;
            }
            &__desc {
                font-size: 16px;
                font-weight: 500;
                color: var(--text-light);
                margin: 0 0 20px;
            }
        }
        &:before,
        &:after {
            content: "";
            width: 60%;
            height: 44px;
            border-radius: 22px;
            background-color: var(--bg-white);
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
        &:before {
            position: absolute;
            top: -22px;
        }
        &:after {
            position: absolute;
            bottom: -22px;
        }

        /*card color changes*/
        &.bg-gradient {
            &:before {
                background-color: #FF5D3B;
            }
            &:after {
                background-color: #FF2C65;
            }
            .app-tour__icon {
                svg {
                    fill: var(--bg-white);
                }
            }
        }
        &.bg-purple {
            &:before,&:after {
                background-color: var(--color-purple);
            }
            .app-tour__icon {
                svg {
                    fill: var(--bg-white);
                }
            }
        }
        &.bg-blue {
            &:before,&:after {
                background-color: var(--color-blue);
            }
            .app-tour__icon {
                svg {
                    fill: var(--bg-white);
                }
            }
        }
        &.bg-success {
            &:before,&:after {
                background-color: var(--color-success);
            }
            .app-tour__icon {
                svg {
                    fill: var(--bg-white);
                }
            }
        }
        &.bg-danger {
            &:before,&:after {
                background-color: var(--color-danger);
            }
            .app-tour__icon {
                svg {
                    fill: var(--bg-white);
                }
            }
        }
    }
    /*owl dots*/
    .owl-dots {
        line-height: 8px;
        position: absolute;
        bottom: 22px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        .owl-dot {
            span {
                width: 8px;
                height: 8px;
                margin: 5px;
                background-color: rgba(255, 255, 255, 0.5);
            }
            &.active {
                span {
                    background-color: var(--bg-white);
                }
            }
        }
    }
    /*owl nav*/
    .owl-nav {
        margin: 0;
        line-height: normal;
        button{
            &.owl-next {
                position: fixed;
                bottom: 30px;
                right: 22px;
                z-index: 2;
                color: var(--text-white);
                margin: 0;
                padding: 6px 12px !important;
                border-radius: 4px;
                @include flexbox;
                @include align-items(center);
                .nav__text {
                    font-size: 16px;
                    font-weight: bold;
                }
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}
.btn-flat.app-tour__skip {
    font-size: 16px;
    color: var(--text-light);
    padding: 6px 12px;
    position: fixed;
    bottom: 30px;
    left: 32px;
    border-radius: 4px;
}
.app-tour__getstarted {
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}
@media (max-width: 359px) {
    .app-tour-carousel {
        height: 340px;
        .app-tour__item {
            width: 300px;
            height: 280px;
            padding: 15px;
            border-radius: 24px;
            .app-tour__icon {
                width: 64px;
                height: 64px;
            }
            .app-tour__title {
                font-size: 22px;
            }
            .app-tour__desc {
                font-size: 15px;
            }
        }
    }
}

/* Promotion Carousel*/
.promotion-carousel {
    .owl-item {
        .item {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            margin-bottom: 10px;
            img{
                border-radius: 10px;
            }
        }
    }
}

/* Pujo Veteran Carousel*/
.pujo-veteran-carousel {
    .owl-item {
        -webkit-backface-visibility: hidden;
        .item {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
            border-radius: 12px;
            img{
                border-radius: 12px;
            }
        }
    }
}
.horizontal-slider {
    @include flexbox;
    @include align-items(center);
    overflow-x: auto;
    overflow-y: hidden;
    .hr-slider-item {
        flex-shrink: 0;
        margin-right: 10px;
        border-radius: 12px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        iframe {
            display: block;
            border-radius: 12px;
        }
        &:first-child {
            margin-left: 20px;
        }
    }
}
