/*Splash Screen*/
.splash-screen {
    background: var(--bg-white);
    position: relative;
    overflow: hidden;
    .splash-screen-container {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        min-height: 100vh;
    }
    .app-intro {
        position: relative;
        text-align: center;
        .app-logo {
            display: block;
            width: 220px;
            height: auto;
            margin: 25px auto;
        }
        h6 {
            font-size: 18px;
            font-weight: 600;
            color: var(--text-black);
            line-height: normal;
            margin: 0;
        }
        .lead {
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            color: var(--text-black);
            margin: 0 0 30px;
        }
    }
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        background: url("../../img/bg_fashion-jumble.png");
        background-position: 50% 0;
        background-size: 100% auto;
        // animation: vertical-scroll 40s linear infinite;
    }
}
@keyframes vertical-scroll {
    0% {
        background-position: 50% 0;
    }
    100% {
        background-position: 50% -1240px;
    }
}
@keyframes vertical-scroll-sm {
    0% {
        background-position: 50% 0;
    }
    100% {
        background-position: 50% -1100px;
    }
}

@media (max-width: 359.98px) {
    .splash-screen {
        .app-intro {
            .app-logo {
                width: 180px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .lead {
                font-size: 16px;
                margin-bottom: 25px;
            }
            h6 {
                font-size: 16px;
            }
        }
    }  
}


/*App unlock*/
.app-unlock {
    &__header {
        .user-mobile-no {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-body);
            white-space: nowrap;
            margin: 0;
            .btn-flat {
                color: var(--text-link);
                vertical-align: baseline;
                padding: 0;
                margin-left: 8px;
            }
        }
    }
    &__title {
        font-size: 28px;
        font-weight: bold;
        color: var(--text-dark);
        margin: 20px 0 12px;
        line-height: 32px;
    }
    &__desc {
        max-width: 320px;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-gray);
        margin: 0 0 5px;
        line-height: normal;
    }
    &__footer {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        p {
            font-family: 'Montserrat';
            font-size: 12px;
            color: var(--text-gray);
            margin: 0;
            text-align: center;
            white-space: nowrap;
            a {
                color: var(--text-highlight);
            }
            strong {
                font-family: 'Montserrat';
                font-weight: 600;
            }
        }
    }
    .has-error-text {
        font-size: 12px;
        color: #F44336 !important;
        margin: 10px 0;
        text-align: left;
    }
}
/*mobile no*/
.mobile-no-block {
    margin: 35px 0 50px;
}
.mobile-no {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    width: 275px;
    border-bottom: 1px solid #E4E6EF;
    &__prepend {
        font-size: 16px;
        font-weight: 500;
        color: var(--text-light);
        margin-right: 10px;
        flex-shrink: 0;
        .icon-mobile {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            background: url('../../img/icons/icon_mobile.svg') center center no-repeat;
            background-size: 20px 20px;
            margin-top: -2px;
            margin-right: 2px;
        }
    }
    .input-field {
        margin: 0;
        flex-grow: 1; 
        &.col {
            label {
                left: 0;
                top: -2px;
                color: rgba(161, 165, 183, 0.75);
                font-weight: 500;
            }
        }
        input{
            border: 0;
            width: 100%;
            letter-spacing: 2px;
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            color: var(--text-main);
            &::placeholder {
                letter-spacing: 0;
                color: rgba(161, 165, 183, 0.75);
            }
            &[type="text"]{
                &:not(.browser-default){
                    &:focus {
                        border: none;
                        box-shadow: none;
                        &:not([readonly]) {
                            + label {
                                color: rgba(161, 165, 183, 0.75);
                            }
                        }
                    }
                }
            }
            &:focus-visible {
                outline: none;
            }
        }

    }
}
/*otp count timer*/
.otp-countdown-timer {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-body);
    text-align: center;
    margin-top: 20px;
    strong {
        font-weight: bold;
        color: #242A37;
    }
}
/*otp resend*/
.otp-resend {
    text-align: center;
    margin-top: 110px;
    p {
        font-family: 'Montserrat';
        font-size: 14px;
        color: var(--text-gray);
        line-height: 18px;
        margin: 0 0 2px;
    }
    .btn-flat {
        background-color: transparent;
        color: var(--text-highlight);
        padding: 0;
        &.disabled,
        &[disabled] {
            background-color: transparent !important;
            color: rgba(255, 44, 101, 0.65) !important;
        }
    }
}

@media (max-width: 359.98px) {
    .app-unlock {
        &__title {
            font-size: 24px;
            line-height: normal;
        }
    }
    /*otp*/
    .otp-countdown-timer {
        font-size: 15px;
        margin-top: 15px;
    }
    .otp-resend {
        margin-top: 80px;
    }
}
@media (max-height: 560px) {
    .mobile-no-block {
        margin: 50px 0 40px;
    }
    .otp-resend {
        margin-top: 80px;
    }
}