/*Variables*/
:root {
    /*Color Palate*/
    --color-primary: #F1416C;
    --color-purple: #7239ea;
    --color-blue: #009ef7;
    --color-success: #50cd89;
    --color-danger: #f1416c;

    /*background color*/
    --bg-white: #ffffff;
    --bg-dark: #000000;
    --bg-dark-blue: #3f4254;
    --bg-gray-dark: #5E6278;
    --bg-alert: #FF2C65;
    --bg-blue: #009EF7;
    --bg-light-yellow: rgba(240, 200, 8, 0.3);
    --bg-light-pink: rgba(232, 106, 146, 0.3);
    --bg-light-ash: rgba(43, 45, 66, 0.3);
    --bg-coupon-code : #FFF8DD;
    
    /*font color*/
    --text-highlight: #FF2C65;
    --text-highlight-red: #FF5D3B;
    --text-white: #ffffff;
    --text-black: #000000;
    --text-light: #A1A5B7;
    --text-dark: #181c32;
    --text-dark1: #2F353B;
    --text-body: #5E6278;
    --text-main: #3f4254;
    --text-gray:#7E8299;
    --text-gray-gold: #51420B;
    --text-gray-light: #707070;
    --text-gray-dark: #343434;
    --text-link: #009EF7;
    --text-dark-light : #424243;

    /*sidenav*/
    --text-sidenav: #5E6278;
    --text-sidenav-active: #000000;
    --bg-sidenav: #FAFAFB;

    /*card colors*/
    --color-purple-light: #f8f5ff;
    --border-purple: rgba(114, 57, 234, 0.1);
    //card blue
    --color-blue-light: #f1faff;
    --border-blue: rgba(0, 158, 247, 0.1);
    //card success
    --color-success-light: #e8fff3;
    --border-success: rgba(80, 205, 137, 0.1);
    //card danger
    --color-danger-light: #fff5f8;
    --border-danger: rgba(241, 65, 108, 0.1);

    /* border color */
    --border-light: #e4e6ef;
    --bg-light: #f5f8fa;

    /* button Color */
    --btn-primary: #FE3168;
    --btn-primary-hover: #FE0B4C;
}
