/* Modal Centered */
.modal__centered {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px;
    background-color: transparent;

    .modal-content {
        position: relative;
        width: 100%;
        max-width: 330px;
        margin: 0 auto;
        border-radius: 10px;
        padding: 0;
        background-color: var(--bg-white);
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.20);
    }

    .modal-footer {
        text-align: center;
        height: auto;
        background-color: transparent;
    }
}

@media (max-width: 359.98px) {
    .modal__centered {
        .modal-content {
            max-width: 300px;
        }
    }
}

/* Start:Modal Bottom Sheet */
.modal {
    .modal-close {
        z-index: 1;

        &.modal-close-icon {
            width: 32px;
            height: 32px;
            display: block;
            position: absolute;
            top: 25px;
            right: 20px;

            img {
                width: 100%;
            }
        }

        &.icon-cross {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            display: block;
            position: absolute;
            top: 10px;
            right: 10px;

            &.icon-cross-dark {

                &:before,
                &:after {
                    background-color: var(--text-main);
                }
            }

            &:before,
            &:after {
                position: absolute;
                left: 15px;
                top: 8px;
                content: ' ';
                height: 16px;
                width: 2px;
                background-color: #E4E6EF;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:hover,
            &:focus,
            &:active {
                transform: scale(1.2);
            }
        }
    }

    &.bottom-sheet {
        max-height: 100%;
        background-color: transparent;
    }

    .modal-footer {
        .btn {
            min-width: 250px;
            margin: 0 auto;
        }
    }

    .progresser-loader {
        min-height: 400px;
        padding: 30px;

        img {
            margin: 60px 0 20px;
        }
    }
}

.modal-overlay {
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.9);
}

.modal__call2action {
    .notch {
        position: relative;
        height: 15px;
        text-align: center;

        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 50%;
            width: calc(100% - 40px);
            height: 30px;
            background-color: #D9D9D9;
            border-radius: 20px;
            transform: translateX(-50%);
            z-index: -1;
        }
    }

    .modal-content {
        background-color: var(--bg-white);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
    }
}

@media (max-width: 359.98px),
(max-height: 560px) {
    .modal {
        .modal-close {
            &.icon-cross {
                top: 5px;
                right: 5px;
            }
        }
    }
}

/* Modal Offer Coupon */
.modal__wallet-offer {
    padding: 0 20px;
    box-shadow: none;

    .modal-content {
        padding: 12px 8px 8px;
        border-radius: 10px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.10);

        h2 {
            font-size: 26px;
            font-weight: bold;
            margin: 0 0 15px;
        }
    }

    .wallet-offer__icon {
        position: relative;
        margin: 50px 0 20px;

        img {
            display: block;
            width: 44px;
            margin: 0 auto;
        }

        &:before,
        &:after {
            content: '';
            width: 75px;
            height: 4px;
            border-radius: 3px;
            position: absolute;
            top: 50%;
            background-color: rgba(217, 217, 217, 0.35);
            transform: translateY(-50%);
        }

        &:before {
            left: 50%;
            margin-left: -105px;
        }

        &:after {
            right: 50%;
            margin-right: -105px;
        }
    }

    .wallet-offer__title {
        font-size: 22px;
        font-weight: bold;
        color: var(--text-dark-light);
        margin-bottom: 25px;
        margin-top: 0;
    }

    .wallet-offer__desc {
        font-size: 16px;
        font-weight: 500;
        color: var(--text-main);
        margin: 0 auto 20px;
    }

    .btn {
        max-width: 310px;
        margin: 30px auto 0;
    }

    .brand-logo {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        width: 250px;
        height: 100px;
        margin: 35px auto 20px;
        border-radius: 5px;
        background-color: #F5F8FA;

        img {
            width: auto;
            height: 80px;
        }
    }

    .notch {
        height: 30px;

        &:before {
            background-color: transparent;
        }
    }

    .offer-description {
        font-weight: 600;
        max-width: 280px;
        margin: 35px auto 65px;
    }

    .validity-text {
        max-width: 260px;
        margin: 0 auto 30px;
    }

    .redeem-text {
        margin-bottom: 20px;
    }
}

.coupon-code-block {
    margin-bottom: 30px;

    small {
        font-size: 12px;
        font-weight: bold;
        line-height: normal;
        color: var(--text-light);
        margin-bottom: 4px;
        display: block;
    }

    p {
        font-weight: 600;
        color: var(--text-main);
        margin: 0 0 15px;
        strong {
            font-size: 15px;
            font-weight: bold;
        }
    }

    .coupon-code-wrap {
        display: inline-block;
        min-width: 150px;
        height: 42px;
        padding: 5px 15px;
        border-radius: 8px;
        border: 2px solid rgba(148, 160, 178, 0.25);
        background-color: var(--bg-coupon-code);

        .coupon-code {
            font-size: 22px;
            font-weight: 500;
            line-height: normal;
            color: var(--text-dark1);
        }
    }
}

.offer-description {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-main);
}

.offer-expiry-info {
    font-size: 11px;
    font-weight: 600;
    color: var(--text-highlight);
    display: block;
    max-width: 85%;
    margin: 0 auto;
}

.text-terms-condition {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-gray);
}

@media (max-width: 359.98px) {
    .modal__wallet-offer {
        .modal-content {
            h2 {
                font-size: 22px;
            }
        }

        .wallet-offer__icon {
            margin-top: 35px;
            margin-bottom: 15px;

            svg {
                width: 56px;
            }
        }

        .wallet-offer__title {
            font-size: 20px;
            margin-bottom: 15px;
        }

        .wallet-offer__desc {
            font-size: 14px;
            margin-bottom: 15px;
        }

        .btn {
            margin-top: 20px;
            max-width: 260px;
        }

        .offer-description {
            font-size: 13px;
            margin-top: 25px;
            margin-bottom: 45px;
        }

        .validity-text {
            font-size: 10px;
            max-width: 220px;
            margin-bottom: 0 auto 25px;
        }

        .redeem-text {
            font-size: 10px;
        }

        .brand-logo {
            margin-top: 30px;
            margin-bottom: 15px;
        }
    }

    .coupon-code-block {
        margin-bottom: 20px;

        small {
            font-size: 11px;
        }

        .coupon-code-wrap {
            min-width: 120px;
            height: 40px;
            border-radius: 6px;
            padding-left: 10px;
            padding-right: 10px;

            .coupon-code {
                font-size: 20px;
            }
        }
    }

    .text-terms-condition {
        font-size: 11px;
    }

    .offer-expiry-info {
        font-size: 11px;
    }
}

@media (max-height: 560px) {
    .modal__wallet-offer {
        .wallet-offer__icon {
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .modal-content h2 {
            font-size: 24px;
        }

        .offer-description {
            margin-top: 25px;
            margin-bottom: 50px;
        }

        .brand-logo {
            margin-top: 25px;
            margin-bottom: 15px;
        }

        .btn {
            margin-top: 20px;
        }

        .validity-text {
            margin-bottom: 20px;
        }
    }

    .coupon-code-block {
        margin-bottom: 25px;

        .coupon-code-wrap {
            height: 38px;
            padding-top: 4px;
            padding-bottom: 4px;

            .coupon-code {
                font-size: 20px;
            }
        }
    }
}

/* Modal Exclusive Deal */
.modal-wallet-exclusive-deal {
    padding: 0 20px;
    box-shadow: none;

    .modal-content {
        padding: 25px 20px 20px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.20);
    }

    .notch {
        height: 30px;

        &:before {
            background-color: transparent;
        }
    }

    .wallet-exclusive-deal__icon {
        margin: 10px 0 20px;
        position: relative;

        &:before {
            content: '';
            width: 46px;
            height: 3px;
            display: inline-block;
            position: absolute;
            bottom: -3px;
            left: 50%;
            border-radius: 50%;
            background-color: rgba(181, 181, 195, 0.35);
            transform: translateX(-50%);
        }

        img {
            display: block;
            width: 84px;
            height: auto;
            margin: 0 auto;
        }
    }

    .wallet-exclusive-deal__title {
        font-size: 22px;
        font-weight: bold;
        color: var(--color-purple);
        margin-bottom: 40px;
        margin-top: 0;
    }

    .wallet-exclusive-deal__text {
        max-width: 280px;
        font-size: 18px;
        font-weight: 600;
        color: var(--text-main);
        line-height: normal;
        margin: 0 auto 40px;
    }

    .btn {
        max-width: 250px;
        margin: 20px auto 30px;
    }
}

@media (max-width: 359.98px) {
    .modal-wallet-exclusive-deal {
        .modal-content {
            padding: 20px 15px 15px;
        }

        .wallet-exclusive-deal__icon {
            margin-bottom: 15px;

            img {
                width: 70px;
            }
        }

        .wallet-exclusive-deal__title {
            font-size: 20px;
            margin-bottom: 25px;
        }

        .wallet-exclusive-deal__text {
            font-size: 16px;
            margin-bottom: 25px;
        }

        .btn {
            margin-top: 15px;
            margin-bottom: 25px;
            max-width: 260px;
        }

        hr {
            margin: 10px auto;
        }
    }
}

/* End:Modal Bottom Sheet */


/* Modal Reward */
.modal__reward {
    .modal-header {
        position: relative;
        width: 100%;
        height: 185px;
        background-color: var(--color-purple);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../../img/bg_flash.svg') center center no-repeat;
            background-size: cover;
        }

        .modal-icon {
            width: 190px;
            height: auto;
            margin: 0 auto;

            img {
                padding-top: 60px;
                width: 100%;
            }
        }
    }

    .modal-body {
        padding: 0 20px;

        h2 {
            font-size: 24px;
            font-weight: 800;
            color: var(--color-purple);
            margin: 20px 0 10px;
        }

        h6 {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-gray);
            margin: 0;
        }

        .reward-description {
            font-size: 14px;
            font-weight: normal;
            color: #515C6F;
            margin-bottom: 20px;

            strong {
                font-weight: bold;
            }
        }

        .validity-text {
            margin-bottom: 25px;
        }
    }

    .modal-footer {
        padding: 10px 20px 15px;
        border-top: 1px dashed #DBDBDB;

        .terms-text {
            font-size: 12px;
            font-weight: 500;
            color: #B4B4B4;
        }
    }
}

.validity-text {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #ED1C24;
    margin-bottom: 15px;
}

.dashed-coupon-code {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 175px;
    margin: 20px auto;
    padding: 8px 15px;
    border-radius: 10px;
    background-color: #FFF3CD;
    border: 2px dashed #F8B900;

    span {
        font-size: 18px;
        font-weight: 800;
        color: #664D03;
        line-height: normal;
    }
}

.reward-info {
    @include flexbox;
    @include align-items(center);
    width: 100%;
    max-width: 300px;
    margin: 0 auto 15px;

    .reward-info__icon {
        width: 30px;
        height: 30px;
        opacity: 0.5;

        img {
            width: 100%;
            display: block;
        }
    }

    .reward-info__content {
        flex: 1 1;
        text-align: left;
        padding-left: 5px;

        p {
            font-size: 12px;
            font-weight: 500;
            color: #727C8E;
            margin: 0;

            a {
                text-decoration: underline;
                color: #727C8E;
                white-space: nowrap;
            }
        }
    }
}

@media (max-width: 359.98px) {
    .modal__reward {
        .modal-header {
            max-height: 160px;

            .modal-icon {
                width: 160px;

                img {
                    padding-top: 50px;
                }
            }
        }

        .modal-body {
            padding-left: 15px;
            padding-right: 15px;

            h2 {
                font-size: 22px;
                margin-bottom: 8px;
            }

            h6 {
                font-size: 13px;
            }

            .reward-description {
                font-size: 13px;
                margin-bottom: 12px;
            }

            .validity-text {
                margin-bottom: 15px;
            }
        }

        .modal-footer {
            .terms-text {
                font-size: 11px;
            }
        }
    }

    .validity-text {
        font-size: 11px;
        margin-bottom: 15px;
    }

    .dashed-coupon-code {
        margin: 18px auto;
        padding: 6px 10px;
        border-radius: 8px;

        span {
            font-size: 16px;
        }
    }
}

/* Modal Welcome Greeting */
.modal__welcome-greeting {
    text-align: center;

    .modal-content {
        padding: 5px;
    }

    .modal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        padding: 15px;
        border-radius: 10px;
        background-color: #DEDEDE;

        &:before {
            content: '';
            width: 250px;
            height: 120px;
            position: absolute;
            top: 15px;
            left: 50%;
            opacity: 0.5;
            background: url('../../img/bg-celebration.svg') center center no-repeat;
            background-size: 250px 120px;
            transform: translateX(-50%);
        }

        .brand-logo {
            width: 180px;
            height: auto;
        }
    }

    .modal-body {
        padding: 10px;

        .user-info {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 15px;

            .__welcome-text {
                font-size: 14px;
                margin-right: 5px;
                color: var(--text-dark);
            }
        }

        .store-details {
            margin-top: 25px;

            .store__welcome-text {
                font-size: 16px;
                font-weight: 600;
                color: var(--bg-dark-blue);
                margin: 0 auto 15px;
            }

            .store__name {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                color: var(--text-main);
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;

                .store__icon {
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    background: url("../../img/icons/icon_store.svg") center center no-repeat;
                    background-size: 24px;
                    margin-right: 5px;
                }

                span {
                    text-align: left;
                }
            }
        }

        .tier-block {
            margin-top: -26px;
        }

        h4 {
            font-size: 18px;
            font-weight: 600;
            color: var(--text-dark-light);
            margin: 40px 0 20px;
        }

        p {
            max-width: 260px;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: var(--text-main);
            margin: 0 auto 35px;

            strong {
                font-weight: bold;
            }
        }
    }

    .modal-footer {
        padding: 2px;
    }
}

.tier-symbol-wrap {
    width: 52px;
    height: 52px;
    padding: 8px;
    margin: 0 auto 2px;
    background-color: var(--bg-white);
    border-radius: 36px;

    .tier-symbol {
        width: 34px;
        height: 39px;
        display: inline-block;
    }
}

.tier-badge {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: var(--text-white);
    border-radius: 15px;
    padding: 5px 12px;
    background-color: transparent;
    text-transform: capitalize;

    img {
        width: 14px;
        margin-right: 5px;
    }
}

.tier-badge-black {
    background-color: #1F1C21;
}

.tier-badge-platinum {
    background-color: #769AD6;
}

.tier-badge-gold {
    background-color: #DEB34A;
}

.tier-badge-silver {
    background-color: #516167;
}

.tier__black {
    .tier-symbol {
        background: url("../../img/icons/icon-tier-badge__black.svg") center center no-repeat;
        background-size: 34px 39px;
    }

    .tier-badge {
        background-color: #1F1C21;
    }
}

.tier__platinum {
    .tier-symbol {
        background: url("../../img/icons/icon-tier-badge__platinum.svg") center center no-repeat;
        background-size: 34px 39px;
    }

    .tier-badge {
        background-color: #769AD6;
    }
}

.tier__gold {
    .tier-symbol {
        background: url("../../img/icons/icon-tier-badge__gold.svg") center center no-repeat;
        background-size: 34px 39px;
    }

    .tier-badge {
        background-color: #DEB34A;
    }
}

.tier__silver {
    .tier-symbol {
        background: url("../../img/icons/icon-tier-badge__silver.svg") center center no-repeat;
        background-size: 34px 39px;
    }

    .tier-badge {
        background-color: #516167;
    }
}

@media (max-width: 359.98px) {
    .modal__welcome-greeting {
        .modal-header {
            min-height: 140px;

            &:before {
                background-size: 200px 100px;
            }

            .brand-logo {
                width: 150px;
            }
        }

        .modal-body {
            .user-info {
                margin-top: 12px;

                .__welcome-text {
                    font-size: 12px;
                }
            }

            .store-details {
                margin-top: 15px;

                .store__welcome-text {
                    font-size: 14px;
                }

                .store__name {
                    font-size: 14px;
                }
            }

            h4 {
                font-size: 16px;
                margin-top: 30px;
                margin-bottom: 15px;
            }

            p {
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 25px;
            }

            .user-info {
                .__user-name {
                    font-size: 13px;
                }
            }
        }

    }

    .tier-symbol-wrap {
        width: 48px;
        height: 48px;
        margin-bottom: 0;

        .tier-symbol {
            width: 30px;
            height: 35px;
            background-size: 30px 35px;
        }
    }

    .tier-badge {
        font-size: 11px;

        img {
            width: 12px;
        }
    }
}

/* Modal Enroll */
.modal__user-enroll {
    .modal-header {
        position: relative;
        padding: 25px 15px 20px;

        img {
            width: 170px;
            height: auto;
            display: block;
            margin: 0 auto;
        }

        &:before {
            content: '';
            width: calc(100% - 16px);
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 8px;
            background-color: #DBDBDB;
        }
    }

    .modal-body {
        text-align: center;
        padding: 50px 24px;

        h4 {
            font-size: 24px;
            font-weight: bold;
            color: var(--text-dark-light);
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            color: var(--text-main);
            margin: 0;
        }
    }

    .modal-footer {
        padding: 8px;

        small {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            color: var(--text-gray);
            margin-top: 10px;
        }
    }
}

@media (max-width: 359.98px) {
    .modal__user-enroll {
        .modal-header {
            padding-top: 20px;

            img {
                width: 140px;
            }
        }

        .modal-body {
            padding: 35px 10px;

            h4 {
                font-size: 22px;
                margin-bottom: 15px;
            }

            p {
                font-size: 13px;
                line-height: 18px;
            }
        }

        .modal-footer {
            small {
                font-size: 11px;
                margin-top: 8px;
            }
        }
    }
}

/* Modal Excluive Deal */
.modal__exclusive-deal {
    .modal-content {
        background-color: transparent;
    }

    .modal-header {
        position: relative;
        height: 145px;
        padding: 20px;
        margin: 0 10px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        background-image: linear-gradient(to bottom, #57A87C 0%, #50CD89 100%);

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url('../../img/bg_flash.svg') center center no-repeat;
            background-size: cover;
        }

        &:after {
            content: '';
            width: 210px;
            height: 105px;
            position: absolute;
            top: 30px;
            left: 50%;
            background: url('../../img/pattern-celebration.svg') center center no-repeat;
            background-size: 100%;
            transform: translateX(-50%);
        }
    }

    .modal-body {
        padding: 10px 24px;
        background-color: var(--bg-white);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .modal-icon-wrap {
            width: 134px;
            height: 134px;
            border-radius: 65px;
            background-color: var(--bg-white);
            padding: 25px;
            margin: -78px auto 0;
            position: relative;

            img {
                display: block;
                width: 84px;
            }
        }

        .congrats-text {
            position: relative;
            margin-top: -15px;
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 12px;
            color: var(--color-purple);
        }

        p {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-gray);
            margin: 0 0 35px;
        }

        .exclusive-deal-desc {
            font-size: 18px;
            font-weight: 800;
            color: var(--text-main);
            margin-bottom: 40px;
        }

        .validity-text {
            margin-bottom: 10px;
        }
    }

    .modal-footer {
        padding: 10px 24px 25px;
        background-color: var(--bg-white);
        border-radius: 0 0 20px 20px;

        .btn {
            text-transform: uppercase;
        }
    }
}

.terms-text {
    font-size: 12px;
    font-weight: 500;
    color: #B4B4B4;
    display: block;
}

@media (max-width: 359.98px) {
    .modal__exclusive-deal {
        .modal-header {
            height: 130px;

            &:after {
                width: 190px;
                height: 95px;
            }
        }

        .modal-body {
            .modal-icon-wrap {
                width: 110px;
                height: 110px;
                padding: 20px;
                margin-top: -65px;

                img {
                    width: 70px;
                }
            }

            .congrats-text {
                font-size: 22px;
                margin-top: -10px;
                margin-bottom: 8px;
            }

            p {
                font-size: 13px;
                margin-bottom: 25px;
            }

            .exclusive-deal-desc {
                font-size: 16px;
                margin-bottom: 30px;
            }

            .validity-text {
                margin-bottom: 6px;
            }
        }

        .modal-footer {
            padding-top: 5px;
            padding-bottom: 20px;
        }
    }

    .terms-text {
        font-size: 11px;
    }
}

@media (max-height: 560px) {
    .modal__exclusive-deal {
        .modal-body {
            p {
                margin-bottom: 25px;
            }

            .exclusive-deal-desc {
                margin-bottom: 30px;
            }
        }
    }
}

/* Modal Pujo Offer */
.modal_pujo-offer {
    .modal-close.icon-cross {
        top: 5px;
        right: 5px;
    }

    .coupon-code-block {
        margin-bottom: 20px;
    }

    .offer-description {
        margin-bottom: 35px;
        line-height: 22px;
    }

    .modal-content {
        border-radius: 10px;
    }

    .modal-body {
        padding: 50px 10px 10px;

        h2 {
            font-size: 26px;
            font-weight: bold;
            color: var(--text-dark);
            margin-bottom: 15px;
        }
    }

    .modal-footer {
        padding: 10px;
        border-top: 1px solid var(--border-light);

        >.btn {
            margin-top: 25px;
        }
    }
}

.redeem-text {
    font-size: 12px;
    font-weight: 600;
    color: rgba(63, 66, 84, 0.75);
    display: block;
    margin-bottom: 10px;
}

/* Call2Action */
.call2action {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    opacity: 0;
    display: none;
    padding: 20px;
    z-index: 1003;
    animation: call2actionfadeUp 0.5s ease-in-out 1;

    &.show {
        opacity: 1;
        display: block;
    }

    &.fade {
        bottom: -40px;
        opacity: 0;
        transition: all 0.35s linear;
    }

    .call2action-content {
        position: relative;
        background-color: var(--bg-white);
        border-radius: 6px;
    }

    .call2action-body {
        padding: 20px 15px 0;
    }

    .call2action-footer {
        padding: 20px;

        .btn {
            height: 35px;
        }
    }

    .loyalty-points-card {
        margin-bottom: 0;
    }
}

.call2action-info {
    @include flexbox;
    @include align-items(center);

    .call2action-info__icon {
        img {
            display: block;
            width: 44px;
            height: auto;
        }
    }

    .call2action-info__content {
        padding-left: 12px;

        p {
            font-size: 13px;
            font-weight: 600;
            color: var(--text-main);
            margin: 0;

            strong {
                font-weight: bold;
            }
        }
    }
}

.call2action-overlay {
    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 1002;
    display: none;
    transition: all 0.5s linear;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.25), rgba(100, 100, 100, 0.75));

    &.show {
        opacity: 1;
        display: block;
    }

    &.fade {
        opacity: 0;
        transition: all 0.5s linear;
    }

    &.call2action-overlay-sm {
        height: 145px;
    }

    &.call2action-overlay-md {
        height: 220px;
    }
}

.call2action-cross-icon {
    width: 28px;
    height: 28px;
    border-radius: 16px;
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;

    &:before,
    &:after {
        position: absolute;
        left: 12px;
        top: 8px;
        content: ' ';
        height: 12px;
        width: 1.5px;
        background-color: var(--text-main);
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.2);
    }
}

@-webkit-keyframes call2actionfadeUp {
    0% {
        bottom: -40px;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes call2actionfadeUp {
    0% {
        bottom: -40px;
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@media (max-width: 359.98px) {
    .call2action {
        padding: 15px;

        .call2action-body {
            padding-top: 15px;
        }

        .call2action-footer {
            padding: 15px;

            .btn {
                height: 30px;
                font-size: 13px;
            }
        }
    }

    .call2action-info {
        .call2action-info__icon {
            img {
                display: block;
                width: 40px;
                height: auto;
            }
        }

        .call2action-info__content {
            padding-left: 8px;
        }
    }
}

/* Call2action Reward End */
.reward-end-info {
    @include flexbox;
    @include align-items(center);

    &__icon {
        width: 44px;
        height: 44px;
        flex-shrink: 0;

        img {
            display: block;
            width: 100%;
        }
    }

    &__content {
        padding-left: 12px;
        flex: 1 1;
    }

    &__title {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-dark1);
        line-height: normal;
        margin-top: 0;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-gray);
        line-height: normal;
        margin-top: 0;
        margin-bottom: 5px;

        a {
            color: var(--text-body);
            white-space: nowrap;
        }
    }

    small {
        display: block;
        font-size: 11px;
        font-weight: 600;
        color: var(--text-highlight-red);
    }
}

@media (max-width: 374.98px) {
    .reward-end-info {
        &__icon {
            width: 40px;
            height: 40px;
        }
    }
}

@media (max-width: 359.98px) {
    .reward-end-info {
        &__icon {
            width: 36px;
            height: 36px;
        }

        &__content {
            padding-left: 8px;
        }

        &__title {
            font-size: 15px;
        }

        &__text {
            font-size: 12px;
        }

        small {
            font-size: 10px;
        }
    }
}

/* Call2action Multiple Membership */
.call2action-info {
    margin-top: 8px;

    .call2action-info__content {
        p {
            font-size: 14px;
        }
    }
}

@media (max-width: 359.98px) {
    .call2action-info {
        margin-top: 5px;

        .call2action-info__content {
            p {
                font-size: 13px;
            }
        }
    }
}