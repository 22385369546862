/* Start: Pujo Veteran */
.pujo-veteran-wrap {
    position: relative;
    // height: 100%;
    padding-bottom: 20px;
    background-color: rgba(250, 232, 231, 0.45);
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.3;
        z-index: -1;
        background: url('../../img/bg_music-pattern.svg') center center repeat;
        background-size: 100% auto;
    }
    .pujo-veteran__img {
        text-align: center;
        padding: 20px;
        img {
            width: 140px;
            height: auto;
        }
    }
    .offer-details {
        padding: 0 20px 30px;
        h6 {
            font-size: 18px;
            font-weight: 500;
            color: var(--text-dark);
            margin-bottom: 16px;
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: var(--text-dark);
            margin-bottom: 30px;
            strong {
                font-weight: 600;
            }
        }
    }
    .nav-arrow {
        margin: 8px;
    }
    .pujo-blockquote {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        strong {
            font-weight: 600;
        }
    }
}
@media (max-width: 359.98px) {
    .pujo-veteran-wrap {
        .pujo-veteran__img {
            padding: 15px;
            img {
                width: 120px;
            }
        }
        .offer-details {
            padding: 0 15px 25px;
            h6 {
                font-size: 16px;
                margin-bottom: 12px;
            }
            p {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 25px;
            }
        }
    }
}
/* End: Pujo Veteran */