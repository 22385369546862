/*Form Enroll*/
.form-enroll {
    // padding-top: 10px;
    .profile-header {
        p {
            max-width: 300px;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            margin: 0 auto;
            color: var(--text-main);
            span {
                color: var(--text-highlight);
            }
        }
    }
    .row {
        margin: 0 -6px;
        .col {
            padding: 0 6px;
        }
    }
    .sticky-footer-btn {
        width: calc(100% - 64px);
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (max-width: 359.98px) {
    .form-enroll {
        .profile-header  {
            p {
                font-size: 13px;
            }
        }
    }
}
/*form group structure*/
.form-group {
    margin-bottom: 20px;
    text-align: left;
    >label {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: var(--text-dark);
        span {
            color: #F1416C;
        }
    }
    >.input-field {
        margin: 0;
        padding: 0;
        &.col {
            padding: 0;
            float: none;
            clear: left;
        }
    }
    input,select,textarea {
        display: block;
        width: 100%;
        height: 38px;
        padding: 8px 12px;
        border: 1px solid #F5F8FA;
        background-color: #F5F8FA;
        border-radius: 5px;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 600;
        color: #564A4A;
        &::placeholder {
            color: var(--text-light);
            font-weight: normal;
        }
    }
    .form-bottomline-text {
        font-size: 12px;
    }
    select {
        option:disabled {
            color: red;
            font-weight: normal;
        }
    }
}

/*Form focus style changes*/
input,select {
    &:focus-visible {
        outline: none;
    }
    &:focus {
        outline: none;
    }
}

/* Start: Custom Switch Button */
.custom-switch{
    position: relative;
    width: 100%;
    height: 38px;
    margin-top: 8px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid var(--border-light);
    [type="checkbox"]{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        cursor: pointer;
        + span:not(.lever){
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 600;
            height: 38px;
            line-height: 34px;
            padding-left: 16px;
            color: var(--text-white);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                width: 50%;
                height: 38px;
                padding: 0;
                margin: 0;
                z-index: -1;
                border: none;  
            }
            &:before {
                content: '';
                left: 0;
                background-color: var(--bg-blue);
            }
            &:after {
                content: 'Married';
                left: auto;
                right: 0;
                text-align: center;
                color: var(--text-light);
                transform: none;
            }
        }
        &:checked {
            + span:not(.lever) {
                color: var(--text-light);
                &:before {
                    width: 50%;
                    height: 38px;
                    top: 0;
                    left: 50%;
                    border: none;
                    background-color: var(--bg-blue);
                    border-radius: 0 5px 5px 0;
                    transform: none;
                }
                &:after{
                    color: var(--text-white);
                }
            }
        }
    }
    [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
        pointer-events: auto;
    }
}
/* End: Custom Switch Button */