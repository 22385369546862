/*Buttons*/
.btn {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: normal;
    box-shadow: none;
    padding: 6px 18px;
    border-radius: 4px;
    color: var(--text-dark);
    text-transform: uppercase;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        box-shadow: none;
    }
}
.btn-fluid {
    width: 100%;
}
.btn-circle {
    border-radius: 24px !important;
}
.btn-sm {
    font-size: 13px;
    height: 30px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: normal;
}
.btn-md {
    font-size: 14px;
    height: 35px;
}
.btn-flat {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: bold;
    color: var(--text-dark);
    text-transform: none;
    height: auto;
    line-height: normal;
    &:hover,&:focus {
        background-color: transparent;
    }
}
.btn-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
}
.btn-sm-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
}
.icon-arrow-right {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: url('../../img/icons/icon_arrow-right-white.svg') center center no-repeat;
    background-size: 18px 18px;
}
.icon-scanner {
    background: url('../../img/icons/icon_scanner-white.svg') center center no-repeat;
    background-size: 18px 18px;
}
.icon-add-circle {
    width: 18px;
    height: 18px;
    display: inline-block;
    background: url('../../img/icons/icon_add-circle.svg') center center no-repeat;
    background-size: 18px 18px;
}
//btn primary
.btn-primary {
    background-color: var(--btn-primary);
    color: var(--text-white);
    &:hover,
    &:focus,
    &:active {
        background-color: var(--btn-primary-hover);
        color: var(--text-white);
    }
    &:disabled,[diabled] {
        background-color: rgba(254, 49, 104, 0.65) !important;
        color: var(--text-white) !important;
        cursor: not-allowed;
    }
}
//btn secondary
.btn-secondary {
    background-color: rgba(63, 66, 84, 0.75);
    color: #f5f8fa !important;
    &:hover,
    &:focus,
    &:active {
        background-color: rgba(63, 66, 84, 1);
        color: #f5f8fa;
    }
}

//btn link
.btn-blue {
    background-color: #F2F3F7;
    color: var(--text-link);
    &:hover,
    &:focus,
    &:active {
        background-color: #E4E6EF;
        color: var(--text-link);
    }
}

//btn dark
.btn-dark {
    background-color: rgba(0, 0, 0, 0.75);
    color: var(--text-white);
    &:hover,
    &:focus,
    &:active {
        background-color: rgba(0, 0, 0, 1);
        color: var(--text-white);
    }
}
@media (max-width: 359.98px) {
    .btn {
        font-size: 14px;
        height: 36px;
        padding: 6px 12px;
    }
    .btn-md {
        font-size: 13px;
        height: 30px;
    }
}