
@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat/Montserrat-Regular.eot');
    src: url('../../fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('../../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
        url('../../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('../../fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat/Montserrat-Medium.eot');
    src: url('../../fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('../../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
        url('../../fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
        url('../../fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat/Montserrat-SemiBold.eot');
    src: url('../../fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('../../fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('../../fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
        url('../../fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../fonts/montserrat/Montserrat-Bold.eot');
  src: url('../../fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
      url('../../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
      url('../../fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
      url('../../fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat/Montserrat-ExtraBold.eot');
    src: url('../../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('../../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../fonts/montserrat/Montserrat-Black.eot');
    src: url('../../fonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
        url('../../fonts/montserrat/Montserrat-Black.woff') format('woff'),
        url('../../fonts/montserrat/Montserrat-Black.ttf') format('truetype'),
        url('../../fonts/montserrat/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Indian Rupee';
    src: url('../../fonts/indianRupee/IndianRupee-Regular.eot');
    src: url('../../fonts/indianRupee/IndianRupee-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/indianRupee/IndianRupee-Regular.woff2') format('woff2'),
        url('../../fonts/indianRupee/IndianRupee-Regular.woff') format('woff'),
        url('../../fonts/indianRupee/IndianRupee-Regular.ttf') format('truetype'),
        url('../../fonts/indianRupee/IndianRupee-Regular.svg#IndianRupee-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

  
  
  