/* mixin header App Icon */
@mixin app-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 44px;
    text-indent: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

/*footer icon*/
@mixin icon-footer {
    width: 26px;
    height: 26px;
    display: block;
    background-size: 26px 26px;
    background-position: center center;
    background-repeat: no-repeat;
}

/* Center Element
both horizontal and 
verticallt */
@mixin center-element {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

// Flexbox display
@mixin flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// Axis Alignment
@mixin justify-content($justify) {
    -webkit-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
}

// Flex Direction
@mixin flex-direction($value) {
    -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

// Cross-axis Alignment
@mixin align-items($align) {
    -webkit-align-items: $align;
    -ms-align-items: $align;
    -ms-flex-align: $align;
    align-items: $align;
}

// Flex Line Wrapping
@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

/* Card Icon */
@mixin base-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
}

@function px-to-vw($px) {
    // Define the base width for your design (usually the width of your viewport)
    $base-width: 375; // You can adjust this based on your design
  
    // Calculate the vw value and round it to the nearest integer
    $vw-value: round(($px / $base-width) * 100);
  
    @return #{$vw-value}vw;
  }