/* Start: Global Error */
.has-error, 
.form-group.has-error {
    input {
        border-color: #F44336 !important;
    }
    &-text {
        font-size: 11px !important;
        font-weight: 500;
        color: #F44336 !important;
        margin: 5px 0 16px;
        .dot {
            width: 6px;
            height: 6px;
            display: inline-block;
            border-radius: 3px;
            background-color: currentColor;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}
/* End: Global Error */

/* Coming Soon Placeholder */
.coming-soon-block {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    width: 220px;
    height: 280px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    border: 8px solid #000;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 0, 0, 0.2) inset;
    .coming-soon-text {
        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0;
        line-height: normal;
        letter-spacing: 6px;
    }
}

/* Progresser Loader */
.progresser-loader {
    text-align: center;
    .progresser {
        width: 165px;
        height: 6px;
        margin: 0 auto;
        border-radius: 3px;
        background-color: #E4E6EF;
        overflow: hidden;
        .progresser-bar {
            display: block;
            width: 40px;
            height: 6px;
            margin-left: -20px;
            border-radius: 3px;
            background-image: linear-gradient(to bottom, #FF5D3B 0%, #FF2C65 100%);
            animation: progresser 1.5s linear infinite;
        }
    }    
    .progresser-text {
        font-size: 12px;
        font-weight: 500;
        color: #515C6F;
        line-height: normal;
        margin: 8px 0 0;
    }
}
@-webkit-keyframes progresser {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(165px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes progresser {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(165px);
    }
    100% {
        transform: translateX(0);
    }
}

/* Start: Toast Style */
#toast-container {
    padding: 10px;
}
.toast {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: var(--text-white);
    border-radius: 20px;
    padding: 4px 12px;
    min-height: 34px;
    margin-top: 6px;
}
@media (max-width: 359.98px) {
    .toast {
        font-size: 12px;
    }
}
/* End: Toast Style */

.tc-link {
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    img {
        width: 16px;
        height: auto;
        margin-left: 5px;
    }
}
@media (max-width: 359.98px) {
    .tc-link {
        font-size: 11px;
        img {
            width: 14px;
        }
    }
}

/* Start: Spinner Border */
.spinner-border {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}
@keyframes spinner-border {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/* End: Spinner Border */

/* Start: Alert */
.alert {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    padding: 10px 12px;
    margin-bottom: 16px;
    border: 1px solid transparent;
    border-radius: 0.25rem; 
    p {
        margin: 0;
        strong {
            font-weight: 600;
        }
    }
}
.alert-danger {
    color: #F44336;
    border-color: rgba(244, 67, 54, 0.15);
    background-color: rgba(244, 67, 54, 0.1);
    hr {
        border-bottom-color: rgba(244, 67, 54, 0.15);
    }
}
/* End: Alert */

/* Start: Circular Loader*/
.loadme {
    &-overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.95);
        z-index: 1005;
    } 
    &-mask {
        background-color: #ddd;
        width: 100px;
        height: 100px;
        position: fixed;
        z-index: 99;
        left: 50%;
        top: 50%;
        margin: -50px 0 0 -50px;
        border-radius: 10px;
        opacity: .5; 
    }
    &-circular,
    &-circular:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    &-circular {
        width: 40px;
        height: 40px;
        position: fixed;
        left: 50%;
        top: 50%;
        margin: -60px 0 0 -20px;
        z-index: 100;
        font-size: 10px;
        text-indent: -9999em;
        border-top: 2px solid rgba(241, 65, 108, 0.25);
        border-right: 2px solid rgba(241, 65, 108, 0.25);
        border-bottom: 2px solid rgba(241, 65, 108, 0.25);
        border-left: 2px solid #F1416C;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: loadme-circular-animate 1.1s infinite linear;
        animation: loadme-circular-animate 1.1s infinite linear; 
    }
    &-text {
        width: 240px;
        height: 40px;
        position: fixed;
        left: 50%;
        top: 50%;
        margin: 0 0 0 -120px;
        z-index: 1001;
        text-align: center;
        font-size: 14px; 
    }
} 
@keyframes loadme-circular-animate {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0); 
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); 
    } 
}
@-webkit-keyframes loadme-circular-animate {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0); 
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); 
    } 
}
/* End: Circular Loader*/