/*App Sidebar*/
.sidenav {
    background-color: var(--bg-sidenav);
    li {
        margin-bottom: 16px;
        line-height: normal;
        &:nth-child(1) {
            margin-bottom: 0;
        }
        &:nth-child(2) {
            margin-top: 10px;
        }
        > a, > button {
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: 600;
            color: var(--text-sidenav);
            padding: 0 24px;
            i {
                margin-right: 16px;
                height: 40px;
                width: 40px;
                line-height: 40px;
            }
            &:hover {
                background-color: rgba(47, 53, 59, 0.17);
            }
            &.active {
                background-color: rgba(47, 53, 59, 0.17);
                color: var(--text-sidenav-active);
            }
        }
        >button {
            width: 100%;
            justify-content: flex-start;
            border-radius: 0;
        }
        // &.active {
        //     a {
        //         background-color: rgba(47, 53, 59, 0.17);
        //         color: var(--text-sidenav-active);
        //     }
        // }
        &.has-divider {
            height: 4px;
            line-height: 4px;
            margin: 0;
            position: relative;
        }
    }
    .sidenav-divider {
        width: calc(100% - 48px);
        height: 1px;
        background-color: #dcdfe3;
        position: absolute;
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 4px;
    }
}
/*Menu Banner*/
.menu-banner {
    height: 140px;
    width: 100%;
    padding: 20px 24px 15px;
    position: relative;
    .brand-logo {
        width: 125px;
        margin: 5px 0 10px;
    }
    .user-info {
        width: 100%;
        .__user-name {
            font-size: 20px;
            color: var(--text-gray-dark);
            line-height: normal;
            margin-bottom: 0;
        }
    }
    .user-loyalty-detail {
        @include justify-content(flex-start);
        .icon-loyalty {
            margin-right: 5px;
        }
        .loyalty-content {
            color: var(--color-success);
            line-height: normal;
            .loyalty-points {
                font-size: 18px;
                display: inline-block;
                + span {
                    font-size: 12px;
                    display: inline-block;
                }
            }
        }
    }
    &:after {
        content: "";
        width: calc(100% - 48px);
        height: 1px;
        background-color: #dcdfe3;
        position: absolute;
        bottom: 0;
    }
}

/*Icons*/
.icon-menu__offers {
    background: url("../../img/icons/icon-menu__offers.svg") center center no-repeat;
}
.icon-menu__promotions {
    background: url("../../img/icons/icon-menu__promotions.svg") center center no-repeat;
}
.icon-menu__loyalty {
    background: url("../../img/icons/icon-menu__loyalty.svg") center center no-repeat;
}
.icon-menu__launches {
    background: url("../../img/icons/icon-menu__launches.svg") center center no-repeat;
}
.icon-menu__apps {
    background: url("../../img/icons/icon-menu__apps.svg") center center no-repeat;
}
.icon-menu__scanner {
    background: url("../../img/icons/icon-menu__scanner.svg") center center no-repeat;
}
.icon-menu__customer-care {
    background: url("../../img/icons/icon-menu__customer-care.svg") center center no-repeat;
}
.icon-menu__profile {
    background: url("../../img/icons/icon-menu__profile.svg") center center no-repeat;
}
.icon-menu__logout {
    background: url("../../img/icons/icon-menu__logout.svg") center center no-repeat;
}
.icon-menu__doc {
    background: url("../../img/icons/icon-menu__doc.svg") center center no-repeat;
}
.icon-menu__home {
    background: url("../../img/icons/icon-menu__home.svg") center center no-repeat;
}

/*Icons Active*/
.active .icon-menu__offers {
    background: url("../../img/icons/icon-menu__offers-active.svg") center center no-repeat;
}
.active .icon-menu__promotions {
    background: url("../../img/icons/icon-menu__promotions-active.svg") center center no-repeat;
}
.active .icon-menu__loyalty {
    background: url("../../img/icons/icon-menu__loyalty-active.svg") center center no-repeat;
}
.active .icon-menu__launches {
    background: url("../../img/icons/icon-menu__launches-active.svg") center center no-repeat;
}
.active .icon-menu__apps {
    background: url("../../img/icons/icon-menu__apps-active.svg") center center no-repeat;
}
.active .icon-menu__scanner {
    background: url("../../img/icons/icon-menu__scanner-active.svg") center center no-repeat;
}
.active .icon-menu__customer-care {
    background: url("../../img/icons/icon-menu__customer-care-active.svg") center center no-repeat;
}
.active .icon-menu__profile {
    background: url("../../img/icons/icon-menu__profile-active.svg") center center no-repeat;
}
.active .icon-menu__logout {
    background: url("../../img/icons/icon-menu__logout-active.svg") center center no-repeat;
}
.active .icon-menu__doc {
    background: url("../../img/icons/icon-menu__doc-active.svg") center center no-repeat;
}
.active .icon-menu__home {
    background: url("../../img/icons/icon-menu__home-active.svg") center center no-repeat;
}

@media (max-width: 359.98px) {
    .menu-banner {
        padding-left: 20px;
        padding-right: 20px;
    }
    .sidenav {
        li {
            margin-bottom: 12px;
            > a, > button {
                padding-left: 20px;
                padding-right: 20px;
            }
            &:last-child {
                margin-bottom: 30px;
            }
        }
        .sidenav-divider {
            top: -4px;
        }
    }
}