/* Start: Brand Offers */
.brand-voucher {
    padding-top: 35px;
    &__img {
        text-align: center;
        margin: 0 0 40px;
        img {
            height: 100px;
            width: auto;
        }
    }
    &__list {
        margin-bottom: 30px;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                display: block;
                font-size: 14px;
                padding: 8px 12px;
                margin-bottom: 20px;
                border-radius: 5px;
                border: 2px solid transparent;
                background-color: #F5F8FA;
                .inr-symbol {
                    font-size: 15px;
                }
                strong {
                    font-weight: 600;
                    margin-right: 5px;
                }
                &:focus,
                &.active {
                    border-color: #ADD9FF;
                    background-color: #E6F3FF;
                }
            }
        }
    }
    .tc-text {
        margin-top: 30px;
    }
}
@media (max-width: 359.98px) {
    .brand-voucher {
        padding-top: 20px;
        &__img {
            margin-bottom: 25px;
            img {
                height: 80px;
            }
        }
        &__list {
            margin-bottom: 25px;
            ul {
                li {
                    font-size: 12px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
/* End: Brand Offers */

/* Start: Redeem Points Style */
.voucher-order-summary {
    padding: 30px 0 10px;
    &__top {
        border-radius: 5px;
        margin-bottom: 30px;
        background-color: #F5F8FA;
        .voucher-brand {
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            img {
                height: 150px;
                width: auto;
                display: block;
            }
        }
    }
    .voucher-order-details {
        font-size: 14px;
        color: var(--text-main);
        margin-bottom: 30px;
        h5 {
            font-size: 14px;
            font-weight: 600;
            margin: 0 0 15px;
            color: var(--text-main);
        }
    }
    .voucher-order-list {
        &__item {
            @include flexbox;
            @include align-items(center);
            @include justify-content(space-between);
            padding: 10px 0;
            label {
                font-size: 14px;
                font-weight: 600;
                color: var(--text-main);
            }
            .value {
                font-weight: bold;
            }
            .form-group {
                select {
                    min-width: 100px;
                    font-weight: bold;
                    color: #484848;
                    height: 30px;
                    padding: 2px 18px;
                    border-color: #DFDFDF;
                    background-color: #ffffff;
                }
            }
            .highlight {
                margin: 24px 0 14px;
            }
        }
        .divider {
            width: 100%;
            height: 1px;
            margin: 5px 0;
            background-color: #DBDBDB;
        }
    }
    .note {
        margin: 0 -20px;
    }
}
.product-counter {
    @include flexbox;
    @include align-items(center);
    width: 100px;
    padding: 2px 6px;
    border-radius: 5px;
    vertical-align: middle;
    border: 1px solid #DFDFDF;
    .btn {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        padding: 2px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: transparent;
    }
    .icon-minus, .icon-plus {
        position: relative;
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background-color: #D00339;
        &:before {
            content: '';
            width: 8px;
            height: 2px;
            display: inline-block;
            background-color: var(--bg-white);
            position: absolute;
            top: 5px;
            left: 2px;
        }
    }
    .icon-plus {
        &:after {
            content: '';
            width: 2px;
            height: 8px;
            display: inline-block;
            background-color: var(--bg-white);
            position: absolute;
            top: 2px;
            left: 5px;
        }
    }
    .input-field {
        margin: 0;
    }
    input {
        font-size: 14px;
        font-weight: bold;
        color: #484848;
        width: 40px;
        border: none;
        text-align: center;
    }
}
@media (max-width: 359.98px) {
    .voucher-order-summary {
        padding-top: 15px;
        padding-bottom: 5px;
        &__top {
            margin-bottom: 20px;
            .voucher-brand {
                img {
                    height: 120px;
                }
            }
        }
        .voucher-order-details {
            font-size: 12px;
            h5 {
                font-size: 12px;
            }
        }
        .voucher-order-list__item {
            padding-top: 8px;
            padding-bottom: 8px;
            label {
                font-size: 12px;
            }
            .highlight {
                margin: 14px 0 4px;
            }
        }
    }
}
/* End: Redeem Points Style */

/* Start: Voucher Doc */
.voucher-doc {
    .voucher-img {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        margin: 20px;
        border-radius: 5px;
        background-color: #F5F8FA;
        img {
            height: 150px;
            width: auto;
            display: block;
        }
    }
    .voucher-title {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-main);
        margin: 30px 20px 0;
    }
    .tabs {
        height: auto;
        padding: 30px 20px;
        .tab {
            width: 33%;
            height: auto;
            line-height: 18px;
            white-space: normal;
            margin-right: 18px;
            text-transform: capitalize;
            a {
                @include flexbox;
                @include align-items(center);
                @include justify-content(center);
                font-weight: 500;
                line-height: 18px;
                padding: 8px;
                color: var(--text-main);
                border-radius: 5px;
                border: 1px solid #F5F8FA;
                background-color: #F5F8FA;
                &.active {
                    border: 1px solid #DEF2FF;
                    background-color: #DEF2FF;
                }
            }
        }
        li {
            &.indicator {
                display: none;
            }
            &:nth-last-child(2) {
                margin-right: 0;
            }
        }
        + .row {
            .col {
                padding: 0 20px;
            }
        }
    }
    .unordered-list {
        li {
            font-size: 11px;
            line-height: 18px;
            &:before {
                background-color: var(--color-primary);
            }
        }
    }
    .tabs-content {
        .col {
            padding: 0 20px;
        }
        .unordered-list {
            li {
                font-size: 11px;
                line-height: 18px;
                &:before {
                    background-color: var(--color-primary);
                }
            }
        }
        // &.carousel.carousel-slider {
        //     .carousel-item {
        //         p{
        //             font-size: 11px;
        //         }
        //     }
        // }
    }
}
@media (max-width: 359.98px) {
    .voucher-doc {
        .tabs {
            padding: 20px 15px;
            .tab {
                a {
                    padding: 6px;
                    font-size: 12px;
                    line-height: 16px;
                    border-radius: 4px;
                }
            }
        }
    }
}
/* End: Voucher Doc */

/* Start: Redeem Point Calculator */
.redeem-points-calculator {
    @include flexbox;
    @include align-items(center);
    margin: 25px -20px 0 -20px;
    background-color: #F5F8FA;
    &__inner {
        @include flexbox;
        @include align-items(center);
        padding: 12px 20px 14px;
    }
    .operand {
        flex: 1 1 auto;
        min-width: 0;
        .form-group {
            margin-bottom: 0;
            label {
                font-size: 10px;
                font-weight: 500;
                line-height: normal;
                display: block;
                text-align: center;
                color: rgba(63, 66, 84, 0.5);
            }
            input {
                height: 30px;
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                line-height: 20px;
                padding: 2px 5px;
                margin-top: 6px;
                border-color: #DEE2E6;
                background-color: #E9ECEF;
            }
        }
        &-sm {
            width: 25%;
        }
    }
    .operator {
        font-size: 12px;
        font-weight: 500;
        margin: 18px 5px 0;
        color: rgba(63, 66, 84, 0.5);
        &--equal {
            font-size: 16px;
            margin-left: 12px;
            margin-right: 12px;
        }
    }
}
@media (max-width: 359.98px) {
    .redeem-points-calculator {
        .operand {
            .form-group {
                input {
                    font-size: 11px;
                }
            }
        }
    }
}
/* End: Redeem Point Calculator */