
.promotion-page {
    .heading-section {
        padding: 16px 20px 20px;
    }
    figure {
        margin: 5px 20px;
        img {
            display: block;
            width: 100%;
        }
        &.promotion-banner {
            border-radius: 10px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            margin-bottom: 15px;
            img {
                border-radius: 10px;
            }
        }
    }
}
@media (max-width: 359.98px) {
    .promotion-page {
        .heading-section {
            padding: 16px 15px 15px;
        }
        figure {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}

/* App Nav Tabs */
.app-nav-tabs {
    height: 84px;
    margin: 10px 0 5px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    .tab {
        &.col {
            width: auto;
            height: 100%;
            flex: 0 0;
            margin-right: 15px;
            &:first-child {
                margin-left: 20px;
            }
        }
        a {
            height: 100%;
            padding: 0;
            &:hover,
            &.active,
            &:focus {
                background-color: transparent;
                &.active {
                    background-color: transparent;
                }
            }
            &.active {
                .nt-menu__icon-wrap {
                    &:before {
                        content: '';
                        width: 54px;
                        height: 54px;
                        border-radius: 28px;
                        border: 1px solid #ee6e73;
                        position: absolute;
                        top: -3px;
                        left: -3px;
                    }
                }
            }
        }
    }
    .indicator {
        display: none
    }
    .nav-tab-menu {
        @include flexbox;
        @include flex-direction(column);
        @include align-items(center);
        width: 64px;
        height: 100%;
        text-align: center;
        .nt-menu__icon-wrap {
            position: relative;
            width: 48px;
            height: 48px;
            margin: 4px;
            border-radius: 25px;
            margin-bottom: 6px;
            padding: 6px;
            .nt-menu__icon {
                width: 36px;
                display: flex;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .nt-menu__text {
            font-size: 12px;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            color: var(--text-dark1);
        }
    }
    &+ .row {
        .col.s12 {
            padding: 0;
        }
    }
}
@media (max-width: 359.98px) {
    .app-nav-tabs {
        .tab {
            &.col {
                margin-right: 10px;
                &:first-child {
                    margin-left: 15px;
                }
            }
        }
    }
}