/* Customer Care Card */
.customer-care-card {
    @include flex-direction(row-reverse);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid rgba(43, 45, 66, 0.15);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    .card-image {
        flex-basis: 50px;
        min-width: 50px;
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-end);
        .icon-container {
            width: 46px;
            height: 46px;
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            border-radius: 25px;
            background-color: transparent;
        }
    }
    .card-content {
        padding: 0;
        .card-title {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-gray);
            margin-bottom: 4px;
            line-height: normal;
            margin-top: 0;
        }
        .card-text {
            font-size: 14px;
            font-weight: bold;
            color: var(--text-dark1);
            line-height: normal;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

/*Customer Care*/
.contact-us-section {
    text-align: center;
    padding-top: 20px;
    h2 {
        font-size: 20px;
        font-weight: bold;
        color: var(--text-dark);
        margin: 0 0 5px;
    }
    p {
        font-size: 12px;
        font-weight: 500;
        color: var(--text-gray);
        margin: 0 0 20px;
    }
}

/*Social Icons*/
.social-icons {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    a {
        line-height: 9px;
        display: inline-block;
        img {
            width: 24px;
            height: auto;
        }
        &+a {
            margin-left: 20px;
        }
    }
}
.icon-card_envelope {
    background: url("../../img/icons/icon-card__envelope.svg");
    @include base-icon;
}
.icon-card_shop {
    background: url("../../img/icons/icon-card__shop.svg");
    @include base-icon;
}
.icon-card_mobile {
    background: url("../../img/icons/icon-card__mobile.svg");
    @include base-icon;
}

@media (max-width: 374.98px) {
    .customer-care-card {
        .card-content {
            .card-text {
                font-size: 13px;
            }
        }
    }
}
@media (max-width: 359.98px) {
    .customer-care-card {
        padding: 10px 12px;
        .card-image {
            flex-basis: 40px;
            min-width: 40px;
            .icon-container {
                width: 36px;
                height: 36px;
                i {
                    width: 18px;
                    height: 18px;
                    background-size: 18px 18px;
                }
            }
        }
        .card-content {
            .card-title {
                font-size: 13px;
            }
            .card-text {
                font-size: 12px;
            }
        }
    }
    .contact-us-section {
        padding-top: 10px;
        h2 {
            font-size: 18px;
        }
    }
}
