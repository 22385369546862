/* Start: Card Flush */
.card-flush {
    position: relative;
    border-radius: 16px;
    margin: 25px 0 12px;
    padding-right: 100px;
    &:before {
        content: '';
        width: 48px;
        height: auto;
        position: absolute;
        top: -12px;
        right: 30px;
        bottom: -12px;
        background: url("../../img/bg_coins.png") center center no-repeat;
        background-size: 100% auto;
        transform: rotate(12deg);
    }
    .card-body {
        padding: 16px 18px;
    }
    .card-title {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-white);
        margin: 0;
        text-transform: uppercase;
    }
    .card-points {
        font-size: 30px;
        font-weight: 800;
        color: var(--text-white);
        margin: 0;
        span {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .expiry-date {
        display: block;
        font-size: 10px;
        color: var(--text-dark);
    }
}
@media (max-width: 359.98px) {
    .card-flush {
        margin-top: 15px;
        margin-bottom: 8px;
        padding-right: 70px;
        &:before {
            width: 40px;
            right: 20px;
        }
        .card-body {
            padding: 12px 15px;
        }
        .card-title {
            font-size: 12px;
        }
        .card-points {
            font-size: 24px;
            span {
                font-size: 16px;
            }
        }
    }
}
/* End: Card Flush */

/* Start: Transaction Cards */

/* Start: Transaction Lists */
.transaction-list {
    &__header {
        padding: 10px 0 15px;
        h5 {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-main);
            margin: 0;
            text-transform: uppercase;
        }
    }
}
/* End: Transaction Lists */

.transaction-card {
    border-radius: 8px;
    margin-bottom: 15px;
    background-color: #F7F7F7;
    .card-body {
        padding: 12px;
    }
    ul {
        display: inline-block;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 5px;
            label {
                font-size: 15px;
                font-weight: 600;
                color: var(--text-dark);
                margin: 0;
            }
            small {
                display: block;
                font-size: 12px;
                color: var(--text-dark);
            }
            span {
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
    .transaction-points {
        @include flexbox();
        @include align-items(center);
        @include justify-content(flex-start);
        margin: 5px 0 10px;
        &__earned,&__redeemed {
            font-size: 12px;
            font-weight: 600;
            color: var(--text-dark);
        }
        &__redeemed {
            margin-left: 14px;
        }
    }
}
@media (max-width: 359.98px) {
    .transaction-list {
        &__header {
            padding-bottom: 12px;
            h5 {
                font-size: 13px;
            }
        }
    }
    .transaction-card {
        border-radius: 6px;
        margin-bottom: 10px;
        .card-body {
            padding: 10px;
        }
        ul {
            li {
                label {
                    font-size: 14px;
                }
                span {
                    font-size: 14px;
                }
                small {
                    font-size: 11px;
                }
            }
        }
        .transaction-points {
            margin-top: 2px;
            margin-bottom: 8px;
            &__earned,&__redeemed {
                font-size: 11px;
            }
        }
    }
}
/* End: Transaction Cards */

/* Start: Partner Brand */
.partner-brand-card {
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #F6F8FA;
    .card-body {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        padding: 8px;
        img {
            width: 110px;
            height: auto;
            display: block;
        }
    }
}
@media (max-width: 359.98px) {
    .partner-brand-card {
        margin-bottom: 15px;
        .card-body {
            img {
                width: 90px;
            }
        }
    }
}
/* End: Partner Brand */