/* Loyalty Points Card */
.loyalty-points-card {
    @include flexbox;
    border-radius: 6px;
    background-color: var(--bg-white);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 10px;
    .icon-box {
        width: 44px;
        height: 44px;
        border-radius: 6px;
        background-color: var(--bg-dark-blue);
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
    }
    .card-stacked {
        @include flexbox;
        @include justify-content(space-between);
        flex: 1 1;
    }
    .card-content {
        margin: 0 10px;
        flex-grow: 1;
    }
    .card-title {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-dark);
        margin-top: 0;
        margin-bottom: 5px;
    }
    .card-text {
        font-size: 12px;
        font-weight: 600;
        color: var(--text-gray);
        margin: 0;
        line-height: normal;
    }
    .loyalty-points__wrapper {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        min-width: 44px;
        height: 44px;
        padding: 4px;
        border-radius: 25px;
        opacity: 0.8;
        color: var(--text-white);
        background-color: #FF2C65;
        .loyalty-points {
            font-size: 15px;
            font-weight: bold;
        }
    }
}
/* Icons */
.icon-card__tour {
    background: url('../../img/icons/icon-card__tour.svg');
}
.icon-card__parking {
    background: url('../../img/icons/icon-card__parking.svg');
}
.loyalty-points__icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 26px 26px;
}
@media (max-width: 359.98px) {
    .loyalty-points-card {
        .icon-box {
            width: 40px;
            height: 40px;
        }
        .card-content {
            margin-left: 8px;
            margin-right: 6px;
        }
        .card-title {
            font-size: 15px;
        }
        .card-text {
            font-size: 11px;
        }
        .loyalty-points__wrapper {
            min-width: 40px;
            height: 40px;
            .loyalty-points {
                font-size: 14px;
            }
        }
    }
    .loyalty-points__icon {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
    }
}

/* Tier Banner */
.tier-wrapper {
    padding: 0 20px;
}
.tier-banner {
    position: relative;
    margin-bottom: 15px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    .tier-banner-img {
        width: 100%;
        display: block;
    }   
    &__left {
        width: 79%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
    }
    &__right {
        width: 21%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        .loyalty-reward-text {
            position: absolute;
            top: 50%;
            left: 50%;
            width: px-to-vw(90);
            height: px-to-vw(32);
            display: inline-block;
            font-size: px-to-vw(14);
            font-weight: bold;
            color: #191B1F;
            line-height: 16px;
            letter-spacing: 2px;
            text-transform: uppercase;
            transform: translate(-50%, -30px) rotate(-90deg);
        }
        .tier-name {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            font-size: px-to-vw(8);
            font-weight: 800;
            color: var(--text-dark);
            text-align: center;
            margin: 0;
            text-transform: uppercase;
            span {
                font-size: px-to-vw(14);
            }
        }
    }
    &.tier-banner--gold,&.tier-banner--platinum {
        .tier-points-details {
            .tier-points__label, .tier-points__value  {
                color: var(--text-gray-gold);
            }
        }
        .tier-user-info {
            .expiry-text {
                color: var(--text-gray-gold);
            }
        }
        .tier-banner__right {
            .loyalty-reward-text {
                color: var(--text-gray-gold);
            }
        }
    }
    &.tier-banner--black {
        .tier-points-details {
            .tier-points__label {
                color: #B5B5C3;
            }
            .tier-points__value  {
                color: var(--text-light);
            }
        }
        .tier-user-info {
            .expiry-text {
                color: var(--text-light);
            }
        }
        .tier-banner__right {
            .tier-name {
                color: var(--text-black);
            }
            .loyalty-reward-text {
                color: var(--text-light);
            }
        }
    }
    &.tier-banner--platinum {
        .tier-points-details {
            .tier-points__value {
                span {
                    color: #191B1F;
                }
            }
        }
        .tier-user-info {
            .user-name {
                color: #191B1F;
            }
        }
    }
}
.tier-points-details {
    position: absolute;
    top: 18px;
    left: 20px;
    .tier-points__label {
        font-size: px-to-vw(14);
        font-weight: 600;
        color: #191B1F;
        margin: 0 0 px-to-vw(4);
        line-height: normal;
        text-transform: uppercase;
    }
    .tier-points__value {
        font-size: px-to-vw(16);
        font-weight: 600;
        color: #191B1F;
        margin: 0;
        line-height: normal;
        span {
            font-size: px-to-vw(24);
            font-weight: 800;
            color: var(--text-white);
        }
    }
}
.tier-user-info {
    position: absolute;
    bottom: 10px;
    left: 20px;
    width: 100%;
    .user-name {
        width: 100%;
        font-size: px-to-vw(14);
        font-weight: bold;
        color: var(--text-white);
        margin: 0 0 px-to-vw(4);
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .expiry-text {
        font-size: px-to-vw(10);
        font-weight: 500;
        color: #191B1F;
        display: block;
        span {
            font-weight: 600;
        }
    }
}

@media (min-width: 481px) {
    .tier-points-details {
        .tier-points__label {
            font-size: 20px;
            margin-bottom: 4px;
        }
        .tier-points__value {
            font-size: 20px;
            span {
                font-size: 30px;
            }
        }
    }
    .tier-user-info {
        .user-name {
            font-size: 20px;
            margin-bottom: 4px;
        }
        .expiry-text {
            font-size: 14px;
        }
    }
    .tier-banner__right {
        .loyalty-reward-text {
            width: 90px;
            height: 32px;
            font-size: 20px;
        }
        .tier-name {
            font-size: 8px;
            span {
                font-size: 20px;
            }
        }
    }
}