/*Placeholder Message*/
.placeholder-msg {
    text-align: center;
    padding: 70px 20px 40px;
    &__icon {
        width: 70px;
        height: auto;
        margin: 0 auto 10px;
        img {
            width: 100%;
            display: block;
        }
    }
    &__text {
        font-size: 14px;
        color: var(--text-gray);
        line-height: normal;
        max-width: 270px;
        margin: 0 auto;
    }
}
@media (max-width: 359.98px) {
    .placeholder-msg {
        padding-top: 40px;
        padding-bottom: 20px;
        &__icon {
            width: 64px;
        }
    }
}
@media (max-height: 560px) {
    .placeholder-msg {
        padding-top: 40px;
        padding-bottom: 20px;
        &__icon {
            width: 64px;
        }
    }
}

/* Start: Centered Message */
.centered-msg {
    margin-top: 15%;
    text-align: center;
    padding: 20px;
    &__icon {
        position: relative;
        width: 90px;
        height: 90px;
        border-radius: 90px;
        background-color: rgba(80, 205, 137, 0.1);
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto; 
        &:before{
            content: '';
            width: 70px;
            height: 70px;
            border-radius: 70px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: rgba(80, 205, 137, 0.1);
        }
        img {
            width: 38px;
            height: auto; 
        }
    }
    &__title{
        font-size: 24px;
        font-weight: bold;
        margin: 15px auto 12px;
        color: var(--text-dark);
    }
    &__desc {
        font-size: 16px;
        max-width: 300px;
        font-weight: normal;
        color: var(--text-main);
        margin: 0 auto 30px;
        line-height: 24px; 
    }
}
/* End: Centered Message */