/* Offer Card */
.offer-card {
    border-radius: 6px;
    transition: none;
    box-shadow: none;
    margin: 0 0 15px;
    &:before,&:after {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 16px;
        z-index: 1;
        background-color: var(--bg-white);
        position: absolute;
        left: 37px;
    }
    &:before {
        top: -9px;
    }
    &:after {
        bottom: -9px;
    }
    .card-image {
        flex-basis: 46px;
        background-color: #D5D5D5;
        border-radius: 6px 0 0 6px;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        .offer-badge-icon {
            position: relative;
            img {
                width: 22px;
            }
            &:before,&:after {
                content: '';
                width: 4px;
                height: 26px;
                border-radius: 3px;
                background-color: rgba(255,255,255,0.35);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            &:before {
                top: -28px;
            }
            &:after {
                bottom: -28px;
            }
        }
    }
    .card-stacked {
        padding: 10px 25px;
        border-left: 1px dashed #D9D9D9;
        background-color: #F4F4F4;
        border-radius: 0 6px 6px 0;
    }
    .card-content {
        padding: 0;
        .card-title {
            font-size: 18px;
            font-weight: 800;
            margin-top: 0;
            margin-bottom: 5px;
            line-height: normal;
            min-height: 28px;
            color: var(--text-dark);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .card-text {
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            margin-top: 5px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: rgba(24, 28, 50, 0.75);
        }
        small {
            font-size: 12px;
            font-weight: 600;
            color: var(--color-primary);
        }
    }
    &.bg-dark {
        .card-stacked {
            background-color: #E6E6E6;
        }
    }
    &.bg-blue {
        .card-title {
            border-color: rgba(0, 108, 171, 0.25);
        }
    }
    &.bg-purple {
        .card-title {
            border-color: rgba(61, 31, 128, 0.25);
        }
    }
    &.bg-success {
        .card-title {
            border-color: rgba(50, 128, 85, 0.25);
        }
    }
    &.bg-danger {
        .card-title {
            border-color: rgba(128, 34, 58, 0.25);
        }
    }
}
@media (max-width: 359.98px) {
    .offer-card {
        margin-bottom: 10px;
        &:before,&:after {
            left: 32px;
        }
        .card-image {
            flex-basis: 40px;
        }
        .card-stacked {
            padding-left: 15px;
            padding-right: 15px;
        }
        .card-content {
            .card-title {
                font-size: 16px;
            }
            .card-text {
                font-size: 13px;
            }
            small {
                font-size: 11px;
            }
        }
    }
}

/* Exclusive Deal Block */
.icon-divider {
    height: 34px;
    margin: 30px 0 10px;
    text-align: center;
    .icon-divider-wrap{
        position: relative;
        display: inline-block;
        &:before,&:after {
            content: '';
            width: 100px;
            height: 4px;
            border-radius: 3px;
            position: absolute;
            top: 50%;
            background-color: rgba(181, 181, 195, 0.15);
            transform: translateY(-50%);
        }
        &:before {
            left: -110px;
        }
        &:after {
            right: -110px;
        }
        
    }
    img {
        width: 34px;
        height: auto;
    }
}
.exclusive-deal-textarea {
    text-align: center;
    h4 {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-body);
        margin: 0 0 8px;
    }
    p {
        font-size: 12px;
        font-weight: 500;
        color: var(--text-gray);
        margin: 0 0 20px;
    }
}
.row {
    &.exclusive-deals {
        margin-left: -7.5px;
        margin-right: -7.5px;
        .col {
            &.s6 {
                padding-left: 7.5px;
                padding-right: 7.5px;
            }
        }
    }
}
.exclusive-deal-card {
    border-radius: 6px;
    margin: 0 0 15px;
    box-shadow: none;
    &:before,&:after {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 16px;
        z-index: 1;
        background-color: var(--bg-white);
        position: absolute;
        top: 50px;
    }
    &:before {
        left: -9px;
    }
    &:after {
        right: -9px;
    }
    .card-header {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        height: 60px;
        border-bottom: 1px dashed #3F4254;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: #D2EFF7;
        padding: 10px 18px;
    }
    .card-icon {
        position: relative;
        img {
            width: 34px;
            height: auto;
            display: block;
        }
        &:before,&:after {
            content: '';
            width: 40px;
            height: 4px;
            display: inline-block;
            border-radius: 3px;
            background-color: rgba(142, 223, 247, 0.5);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before{
            left: -42px;
        }
        &:after {
            right: -42px;
        }
    }
    .card-content {
        position: relative;
        padding: 12px 8px 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        background-color: #EFF0F0;
        text-align: center;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url("../../img/pattern_reward-jumble.svg") center center no-repeat;
            background-size: cover;
        }
        .brand-logo {
            img {
                display: block;
                height: 56px;
                width: auto;
                margin: 0 auto;
            }
        }
        .card-title {
            font-size: 18px;
            font-weight: 800;
            line-height: 22px;
            color: var(--text-white);
            margin: 0 0 8px;
            span {
                display: block;
            }
        }
        small {
            display: inline-block;
            font-size: 10px;
            font-weight: 600;
            color: rgba(241, 65, 108, 0.75);
            strong {
                color: var(--color-primary);
                margin-left: 5px;
                font-weight: 600;
                white-space: nowrap;
            }
        }
    }
    .card-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(247, 245, 245, 0.9);
    }
    .badge-redeem {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 12px;
        font-weight: 600;
        color: var(--text-white);
        padding: 8px;
        background-color: #50CD89;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}
.exclusive-deal-card--orange {
    .card-content {
        background-color: #F28E41;
    }
}
.exclusive-deal-card--red {
    .card-content {
        background-color: #DD6F62;
    }
}
.exclusive-deal-card--blue {
    .card-content {
        background-color: #729BEF;
    }
}
.exclusive-deal-card--skyblue {
    .card-content {
        background-color: #4FBBD5;
    }
}
@media (max-width: 359.98px) {
    .icon-divider {
        height: 30px;
        .icon-divider-wrap {
            &:before,&:after {
                height: 3px;
            }
            img {
                width: 30px;
            }
        }
    }
    .exclusive-deal-congrats {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .exclusive-deal-card {
        &:before,&:after {
            top: 40px;
        }
        .card-header {
            padding: 8px 15px;
            height: 50px;
        }
        .card-icon {
            &:before,&:after {
                width: 35px;
                height: 3px;
            }
            &:before {
                left: -35px;
            }
            &:after {
                right: -35px;
            }
            img {
                width: 30px;
            }
        }
        .card-content {
            padding-top: 15px;
            padding-bottom: 12px;
            .card-title {
                font-size: 16px;
                line-height: 18px;
            }
            small {
                font-size: 9px;
            }
        }
    }
}