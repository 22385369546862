/*global*/
.container {
    width: 100%;
    padding: 0 20px;
}
.screen-wrap {
    max-width: 480px;
    height: 100%;
    margin: 0 auto;
}
.main-wrap {
    width: 100%;
    height: calc(100% - 122px);
    overflow-y: auto;
    overflow-x: hidden;
    &.has-no-footer {
        height: calc(100% - 56px);
    }
    &.has-fixed-bottom {
        height: calc(100% - 156px);
    }
}
.heading-section {
    padding: 16px 0 20px;
}
.heading-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--text-dark);
    margin: 0;
}
.heading-desc {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-gray);
    margin: 0;
}
@media (max-width: 359.98px) {
    .container {
        padding: 0 15px;
    }
    .main-wrap {
        height: calc(100% - 116px);
    }
    .heading-section {
        padding-bottom: 16px;
    }
    .heading-title {
        font-size: 18px;
    }
}

/*Terms and Conditions*/
.app-tc-page {
    padding: 20px 0 35px;
    .app-tc__title {
        font-size: 16px;
        font-weight: 800;
        line-height: normal;
        color: var(--text-main);
        margin: 0 0 12px;
        text-transform: uppercase;
    }
    .app-tc__desc {
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: 20px;
        color: var(--text-gray-light);
        margin: 0 0 15px;
    }
    .app-tc__sub-title {
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        color: var(--text-main);
        margin: 0 0 10px;
    }
    ol.app-tc__list,
    ul.app-tc__list {
        padding-left: 34px;
        margin-top: 10px;
        list-style-type: none;
        counter-reset: item;
        .app-tc__list-item {
            font-family: 'Montserrat';
            font-size: 14px;
            line-height: 16px;
            color: var(--text-gray-light);
            margin-bottom: 10px;
            counter-increment: item;
            a {
                color: var(--text-link);
            }
        }
    }
    ol.app-tc__list {
        .app-tc__list-item {
            &:before {
                content: counter(item) ".";
                width: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-left: -26px;
            }
        }
    }
    ul.app-tc__list {
        .app-tc__list-item {
            &:before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 4px;
                background-color: #B5B5C3;
                display: inline-block;
                vertical-align: middle;
                margin-left: -20px;
                margin-right: 14px;
            }
        }
    }
}

/*Mobile Apps*/
.mobile-appstore-container {
    padding: 0 10px;
}
.mobile-appstore-grid {
    margin-top: 90px;
    .row {
        .col {
            padding: 0 10px;
            &:first-child {
                .mobile-appstore-item {
                    .mobile-appstore-btn {
                        img {
                            width: 87%;
                        }
                    }
                }
            }
            &:last-child {
                .mobile-appstore-item {
                    .mobile-appstore-btn {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .mobile-mockup {
        width: 85px;
        margin-bottom: 20px;
        margin-top: -55px;
    }
    .mobile-appstore-item {
        background-color: #F5F5F7;
        border-radius: 18px;
        text-align: center;
        padding: 25px 10px;
    }
    .mobile-appstore-title {
        font-size: 14px;
        font-weight: bold;
        color: var(--text-body);
        margin-top: 0;
        margin-bottom: 10px;
    }
}
@media (max-width: 359.98px) {
    .mobile-appstore-container {
        padding: 0 5px;
    }
    .mobile-appstore-grid {
        margin-top: 70px;
    }
}

/* Start: Banner Section */
.banner-list {
    margin-bottom: 20px;
    &__head {
        @include flexbox;
        @include align-items(center);
        padding: 5px 20px;
    }
    &__title {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-black);
        margin: 0;
    }
    &__body {
        padding: 10px 20px;
        iframe {
            border-radius: 12px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
        figure {
            margin: 0;
            .figure-img {
                width: 100%;
                display: block;
            }
        }
    }
}
@media (max-width: 359.98px) {
    .banner-list {
        margin-bottom: 15px;
        &__title {
            font-size: 15px;
        }
        &__body {
            padding: 5px 15px;
        }
    }
}
.blur-effect-box {
    width: 100%;
    height: 220px;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.35;
    z-index: -1;
    background-image: linear-gradient(to bottom, #ffffff 0%, #646464 100%);
}
/* End: Banner Section */

/* Start: Reward Points */
.reward-points {
    padding: 24px 0;
    border-bottom: 1px solid #DBDBDB;
    &__label {
        font-size: 15px;
        font-weight: 600;
        color: var(--text-dark);
        margin-bottom: 5px;
    }
    &__value {
        font-weight: bold;
    }
    &__expiry {
        font-size: 12px;
        color: var(--text-dark);
        margin: 0;
    }
}
@media (max-width: 359.98px) {
    .reward-points {
        padding-top: 16px;
        padding-bottom: 16px;
        &__label {
            font-size: 14px;
        }
        &__expiry {
            font-size: 11px;
        }
    }
}
/* End: Reward Points */

/* Start: Fixed Bottom Layout */
.fixed-bottom-layout {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    z-index: 2;
    background-color: var(--bg-white);
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.05);
    .btn-fluid {
        margin-bottom: 20px;
    }
}
@media (max-width: 359.98px) {
    .fixed-bottom-layout {
        padding: 15px;
    }
}
/* End: Fixed Bottom Layout */

/* start: Underordered List */
.unordered-list {
    padding-left: 30px !important;
    li {
        position: relative;
        font-size: 12px;
        margin-bottom: 15px;
        color: #404254;
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 4px;
            position: absolute;
            top: 6px;
            left: -30px;
            background-color: var(--text-light);
        }
    }
}
@media (max-width: 359.98px) {
    .unordered-list {
        padding-left: 20px !important;
        li {
            margin-bottom: 10px;
            &:before {
                left: -20px;
            }
        }
    }
}
/* End: Unordered List */