
/* Launches card */
.brand-voucher-card {
    background-color: var(--bg-white);
    border-radius: 10px;
    margin-bottom: 25px;
    box-shadow: 0 0 20px rgba(51, 83, 145, 0.16);
    &.horizontal {
        .card-image {
            width: 125px;
            &> img {
                width: 100%;
                height: auto;
                border-radius: 10px 0 10px 10px;
            }
        }
    }
    .image-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include flexbox;
        @include align-items(center);
        @include justify-content(flex-end);
        border-radius: 10px 0 10px 10px;
    }
    .card-content {
        padding: 8px 12px 2px;
        .card-title {
            font-size: 16px;
            font-weight: bold;
            color: var(--text-main);
            line-height: normal;
            margin: 0;
            text-transform: uppercase;
        }
        .card-text {
            font-size: 13px;
            font-weight: 500;
            color: var(--text-main);
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    .card-action {
        @include flexbox;
        @include justify-content(flex-end);
        padding: 0 2px 2px;
        border-top: 0;
        &> .btn {
            font-size: 12px;
            padding-top: 4px;
            padding-bottom: 4px;
            height: 24px;
        }
    }
}