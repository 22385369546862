/*global element*/
html,
body {
    height: 100%;
}
body {
    font-family: "Montserrat";
    font-weight: normal;
}
#root {
    height: 100%;
}
h1,h2,h3,h4,h5,h6,p {
    line-height: normal;
}
a {
    color: #009EF7;
}
.inr-symbol {
    font-family: 'Indian Rupee';
}
/* Sizing */
.w-100 {
    width: 100% !important;
}
.h-100 {
    height: 100% !important;
}
.h-auto {
    height: auto !important;
}
.mw-100 {
    max-width: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}

/* Position */
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }

/*Display*/
.d-flex {
    display: flex !important;
}
.d-block {
    display: block !important;
}
.d-table { 
    display: table !important;
}
.d-table-cell { 
    display: table-cell !important;
}

/*Vertical Align Middle*/
.text-center {
    text-align: center !important;
}
.align-middle { 
    vertical-align: middle !important; 
}
.align-bottom { 
    vertical-align: bottom !important; 
}
.align-items-start {
    align-items: start !important;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}

/*Padding*/
.p-0 {
    padding: 0 !important;
}
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
/*Margin*/
.m-0 {
    margin: 0 !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.ml-5 {
    margin-left: 5px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.my-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.mt-30 {
    margin-top: 30px;
}
.my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}
@media (max-width: 359.98px) {
    .my-30 {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
/*text color*/
.text-white {
    color: var(--text-white) !important;
}
.text-purple {
    color: var(--color-purple) !important;
}
.text-blue {
    color: var(--color-blue) !important;
}
.text-success {
    color: var(--color-success) !important;
}
.text-danger {
    color: var(--color-danger) !important;
}
.text-muted {
    color: var(--text-gray) !important;
}
.text-primary {
    color: var(--color-primary) !important;
}

/*Background Purple*/
.bg-gradient {
    background: linear-gradient(to bottom, #ff5d3b, #ff2c65) !important;
    color: var(--text-white) !important;
}
.bg-purple {
    background-color: var(--color-purple) !important;
    color: var(--text-white) !important;
}
.bg-blue {
    background-color: var(--color-blue) !important;
    color: var(--text-white) !important;
}
.bg-success {
    background-color: var(--color-success) !important;
    color: var(--text-white) !important;
}
.bg-danger {
    background-color: var(--color-danger) !important;
    color: var(--text-white) !important;
}
.bg-primary {
    background-color: var(--color-primary) !important;
}
.bg-light-yellow {
    background-color: var(--bg-light-yellow) !important;
}
.bg-light-pink {
    background-color: var(--bg-light-pink) !important;
}
.bg-light-ash {
    background-color: var(--bg-light-ash) !important;
}
.card-light-danger {
    background-color: #FFF5F8;
}
.card-light-success {
    background-color: #EAFDF3;
}
.card-light-purple {
    background-color: #F9F5FF;
}
.card-light-blue {
    background-color: #F1FAFF;
}

/*card catwfory colors*/
.card-category-purple {
    background-color: var(--color-purple-light) !important;
    border-color: var(--border-purple) !important;
}
//card blue
.card-category-blue {
    background-color: var(--color-blue-light) !important;
    border-color: var(--border-blue) !important;
}
//card success
.card-category-success {
    background-color: var(--color-success-light) !important;
    border-color: var(--border-success) !important;
}
//card danger
.card-category-danger {
    background-color: var(--color-danger-light) !important;
    border-color: var(--border-danger) !important;
}

/* Overlay colors*/
.overlay-light-gray {
    background-color: rgba(228, 230, 239, 0.45);
}
.overlay-light-green {
    background-color: rgba(80, 205, 137, 0.45);
}
.overlay-light-purple {
    background-color: rgba(114, 57, 234, 0.45);
}
.overlay-light-yellow {
    background-color: rgba(241, 200, 65, 0.45);
}

/* Background extra Light */
.bg-extra-light-green {
    background-color: rgba(80, 205, 137, 0.15);
}   
.bg-extra-light-purple {
    background-color: rgba(114, 57, 234, 0.15);
}
.bg-extra-light-blue {
    background-color: rgba(0, 158, 247, 0.15);
}
.bg-extra-light-pink {
    background-color: rgba(241, 65, 108, 0.15);
}
.bg-extra-light-yellow {
    background-color: rgba(255, 199, 0, 0.15);
}

/* waves effect */
.waves-effect.waves-light-dark .waves-ripple {
    background-color: rgba(0, 0, 0, 0.1);
}
.waves-effect.waves-light-success .waves-ripple {
    background-color: rgba(80, 205, 137, 0.1);
}
.waves-effect.waves-light-danger .waves-ripple {
    background-color: rgba(241, 65, 108, 0.1);
}
.waves-effect.waves-light-blue .waves-ripple {
    background-color: rgba(0, 158, 247, 0.1);
}
.waves-effect.waves-light-purple .waves-ripple {
    background-color: rgba(114, 57, 234, 0.1);
}
.waves-effect.waves-light-primary .waves-ripple {
    background-color: rgba(241, 65, 108, 0.1);
}
/* Text Styles */
.fw-500 {
    font-weight: 500 !important;
}
.text-uppercase {
    text-transform: uppercase !important;
}
.text-transform-none {
    text-transform: none !important;
}
.text-decoration-none {
    text-decoration: none !important;
}

hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid rgba(153, 159, 166, 0.25);
    margin: 1rem auto;
}
.border-dashed {
    border-style: dashed !important;
}

.hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        width: 0; /* Hide the scrollbar width */
        background: transparent; /* Make the background transparent */
    }
    /* Optionally, you can style the scrollbar track and thumb */
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track transparent */
    }
    &::-webkit-scrollbar-thumb {
        background: transparent; /* Make the thumb transparent */
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
/* notes */
.highlight {
    padding: 8px 12px;
    border-radius: 5px;
    color: #404254;
    border-color: #F5F8FA;
    background-color: #F5F8FA;
    > small {
        display: block;
        font-size: 10px;
        margin-bottom: 4px;
    }
    p {
        margin: 0;
        strong {
            font-weight: bold;
        }
    }
}
/* Start: Note Style */
.note {
    background-color: #F5F8FA;
    &-body {
        padding: 20px;
    }
    &-title {
        font-size: 11px;
        font-weight: 600;
        color: rgba(63, 66, 84, 0.75);
        margin: 0 0 10px;
    }
    &-text {
        font-size: 12px;
        font-weight: 600;
        color: var(--text-main);
        margin: 0;
        & + .note-text {
            margin-top: 10px;
        }
    }
}
/* End: Note Style */