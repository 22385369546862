
/*customer details block*/
.user-details {
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    padding: 12px 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--bg-white);
}
.user-info {
    width: calc(100% - 70px);
    .__welcome-text {
        font-size: 10px;
        font-weight: 600;
        color: var(--text-light);
        line-height: 12px;
        margin: 0;
    }
    .__user-name {
        font-size: 14px;
        font-weight: 800;
        color: var(--text-dark);
        margin: 0;
        line-height: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
}
.user-loyalty-detail {
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-between);
    .icon-loyalty {
        width: 24px;
        height: 24px;
        background: url("../../img/icons/icon_loyalty-green.svg") center center no-repeat;
        background-size: 24px 24px;
        margin-right: 2px;
    }
    .loyalty-content {
        text-align: center;
        color: var(--text-main);
        .loyalty-points {
            font-size: 16px;
            font-weight: 800;
            margin: 0;
            & + span {
                font-size: 8px;
                font-weight: 500;
                display: block;
            }
        }
    }
    .app-tour__pointer-btn {
        position: absolute;
        right: 32px;
        top: -4px;
    }
}

@media (max-width: 359.98px) {
    .container {
        padding: 0 15px;
    }
    .main-wrap {
        height: calc(100% - 116px);
    }
    .user-details {
        padding: 10px 15px;
    }
    .user-info {
        .__user-name {
            font-size: 16px;
        }
    }
    .user-loyalty-detail {
        .loyalty-content {
            .loyalty-points {
                font-size: 14px;
            }
        }
        .app-tour__pointer-btn {
            right: 28px;
            top: 0px;
        }
    }
}

/*home card category*/
.section-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-main);
    padding: 16px 20px;
    margin: 0;
    .app-tour__pointer-btn {
        margin-left: 15px;
        position: relative;
    }
}

.card {
    &.card-category {
        width: 85px;
        height: 110px;
        border: 1px solid #eee;
        border-radius: 24px;
        text-align: center;
        padding: 5px;
        margin: 0;
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column);
        box-shadow: none;
        .card-icon {
            margin-bottom: 6px;
            svg {
                width: 34px;
                height: 34px;
                display: block;
                fill: var(--text-main);
            }
        }
        .card-text {
            font-size: 14px;
            font-weight: bold;
            color: var(--text-main);
            line-height: 14px;
        }
        .card-content {
            padding: 0;
        }

        /*card modifier*/
        &.card-category-purple {
            .card-icon {
                svg {
                    fill: var(--color-purple) !important;
                }
            }
        }
        &.card-category-blue {
            .card-icon {
                svg {
                    fill: var(--color-blue) !important;
                }
            }
        }
        &.card-category-success {
            .card-icon {
                svg {
                    fill: var(--color-success) !important;
                }
            }
        }
        &.card-category-danger {
            .card-icon {
                svg {
                    fill: var(--color-danger) !important;
                }
            }
        }
    }
}

@media (max-width: 359.98px) {
    .section-title {
        font-size: 14px;
        padding: 10px 15px;
    }
    .card{
        &.card-category {
            width: 80px;
            height: 100px;
            border-radius: 22px;
            .card-icon {
                svg {
                    width: 28px;
                    height: 28px;
                }
            }
            .card-text {
                font-size: 12px;
                line-height: 13px;
            }
        }
    }
    .card-category-carousel {
        .owl-item.active {
            .item.first-item {
                margin-left: 15px;
            }
        }
    }
}

/* Card Special Offers */
.spl-offer-container {
    padding: 0 12px 0 20px;
    .card__spl-offer {
        margin: 0;
        padding: 10px 0;
        &:before {
            content: "";
            width: calc(100% - 70px);
            height: 1px;
            position: absolute;
            top: 0;
            right: 0;
            border-top: 1px dashed var(--border-light);
        }
        &:first-child {
            padding-top: 0;
            &:before {
                content: none;
            }
        }
    }
}
.card__spl-offer {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    box-shadow: none;
    margin: 0 0 10px;
    .card-image {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        border: 1px dashed var(--border-light);
        padding: 1px;
        .card-box {
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            width: 56px;
            height: 56px;
            border-radius: 10px;
            background-color: var(--bg-light);
            img {
                width: 48px;
                height: auto;
            }
        }
    }
    .card-content {
        padding: 0 15px 0 10px;
        flex-grow: 1;
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: #7e8299;
            flex-grow: 1;
        }
    }
    .card-action {
        border: none;
        padding: 0;
        flex-shrink: 0;
        .btn {
            font-weight: 800;
        }
    }
}

@media (max-width: 359.98px) {
    .spl-offer-container {
        padding-right: 10px;
        padding-left: 15px;
        .card__spl-offer {
            padding: 8px 0;
            &:before {
                width: calc(100% - 62px);
            }
        }
    }
    .card__spl-offer {
        .card-image {
            width: 54px;
            height: 54px;
            border-radius: 8px;
            .card-box {
                width: 50px;
                height: 50px;
                border-radius: 8px;
                img {
                    width: 40px;
                }
            }
        }
        .card-content {
            padding-left: 8px;
            padding-right: 10px;
            p {
                font-size: 12px;
            }
        }
        .card-action {
            .btn-sm {
                font-size: 12px;
            }
        }
    }
}

/* App Tour Pointer Bottom */
.app-tour__pointer-bottom {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}
/*Page backdrop*/
.page-backdrop {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(63, 66, 84, 0.2);
    &.show {
        display: block;
        z-index: 2;
    }
}

/*QR Code Info*/
.qrcode-info {
    text-align: center;
    margin: 20px 24px 30px;
    border-top: 1px dashed #E4E6EF;
    .qrcode-info__title {
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        font-size: 20px;
        font-weight: 800;
        color: var(--text-main);
        margin: 25px 0 10px;
    }
    .qrcode-info__icon {
        width: 32px;
        height: auto;
        margin-right: 10px;
        img {
            display: block;
            width: 100%;
        }
    }
    .qrcode-info__text {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-gray);
        margin: 0 0 20px;
        line-height: normal;
    }
    .btn {
        min-width: 140px;
        margin: 0 auto;
    }
}
@media (max-width: 359.98px) {
    .qrcode-info {
        margin: 15px 15px 20px;
        .qrcode-info__title {
            font-size: 18px;
            margin-top: 18px;
            margin-bottom: 8px;
        }
        .qrcode-info__icon {
            width: 28px;
            margin-right: 8px;
        }
        .qrcode-info__text {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }
}

/* Home Nav Grid */
.home-nav-grid {
    padding: 0 15px;
    .col {
        padding: 0 10px;
    }
    .card {
        text-align: center;
        margin: 10px auto;
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
    .card-icon {
        margin: 4px 0 10px;
        img {
            display: block;
            height: 54px;
            width: auto;
            margin: 0 auto;
        }
    }
    .card-label {
        font-size: 14px;
        font-weight: 500;
        color: var(--text-black);
        margin: 0;
    }
}
@media (max-width: 359.98px) {
    .home-nav-grid {
        .card-icon {
            img {
                height: 48px;
            }
        }
        .card-label {
            font-size: 13px;
        }
    }
}