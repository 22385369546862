/*app footer*/
.app-footer {
    height: 66px;
    background-color: var(--bg-white);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 0 20px rgba(82, 63, 105, 0.15);
    position: relative;
    z-index: 1;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-around);
    .app-footer__icon {
        width: 46px;
        height: 46px;
        padding: 10px;
        border-radius: 24px;
    }
    .active {
        .icon-footer {
            &__qrcode {
                background-image: url("../../img/icons/icon-footer__qrcode-active.svg");
            }
            &__loyalty {
                background-image: url("../../img/icons/icon-footer__loyalty-active.svg");
            }
            &__offers {
                background-image: url("../../img/icons/icon-footer__offers-active.svg");
            }
            &__customer-care {
                background-image: url("../../img/icons/icon-footer__customer-care-active.svg");
            }
            &__barcode {
                background-image: url("../../img/icons/icon-footer__barcode-active.svg");
            }
            &__offers {
                background-image: url("../../img/icons/icon-footer__offers-active.svg");
            }
            &__profile {
                background-image: url("../../img/icons/icon-footer__profile-active.svg");
            }
            &__mobile-apps {
                background-image: url("../../img/icons/icon-footer__mobile-apps-active.svg");
            }
            &__home {
                background-image: url("../../img/icons/icon-footer__home-active.svg");
            }
        }
    }
}
/*footer icons*/
.icon-footer {
    &__qrcode {
        background-image: url("../../img/icons/icon-footer__qrcode.svg");
        @include icon-footer;
    }
    &__loyalty {
        background-image: url("../../img/icons/icon-footer__loyalty.svg");
        @include icon-footer;
    }
    &__offers {
        background-image: url("../../img/icons/icon-footer__offers.svg");
        @include icon-footer;
    }
    &__customer-care {
        background-image: url("../../img/icons/icon-footer__customer-care.svg");
        @include icon-footer;
    }
    &__barcode {
        background-image: url("../../img/icons/icon-footer__barcode.svg");
        @include icon-footer;
    }
    &__offers {
        background-image: url("../../img/icons/icon-footer__offers.svg");
        @include icon-footer;
    }
    &__profile {
        background-image: url("../../img/icons/icon-footer__profile.svg");
        @include icon-footer;
    }
    &__mobile-apps {
        background-image: url("../../img/icons/icon-footer__mobile-apps.svg");
        @include icon-footer;
    }
    &__home {
        background-image: url("../../img/icons/icon-footer__home.svg");
        @include icon-footer;
    }
}

@media (max-width: 359.98px) {
    .app-footer {
        height: 60px;
    }
}