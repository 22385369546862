/*Scanner*/
.app-scanner-block {
    height: 100%;
    width: 100%;
    &> div {
        padding: 0 !important;
        height: 100%;
    }
    video {
        width: auto !important;
    }
}
.app-scanner {
    width: 240px;
    height: 240px;
    background-color: transparent;
    border-radius: 335px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-sizing: content-box;
    border: 300px solid rgba(0, 0, 0, 0.75);
}
.app-scanner-border__topleft,
.app-scanner-border__topright,
.app-scanner-border__bottomleft,
.app-scanner-border__bottomright {
    position: fixed;
    width: 60px;
    height: 60px;
    border-style: solid;
    border-color: rgba(241, 65, 108, 0.5);
}
.app-scanner-border__topleft {
    top: 0;
    left: 0;
    border-width: 6px 0 0 6px;
    margin-top: -18px;
    margin-left: -18px;
    border-top-left-radius: 50px;
}
.app-scanner-border__topright {
    top: 0;
    right: 0;
    border-width: 6px 6px 0 0;
    margin-top: -18px;
    margin-right: -18px;
    border-top-right-radius: 50px;
}
.app-scanner-border__bottomleft {
    bottom: 0;
    left: 0;
    border-width: 0 0 6px 6px;
    margin-bottom: -18px;
    margin-left: -18px;
    border-bottom-left-radius: 50px;
}
.app-scanner-border__bottomright {
    bottom: 0;
    right: 0;
    border-width: 0 6px 6px 0;
    margin-bottom: -18px;
    margin-right: -18px;
    border-bottom-right-radius: 50px;
}
.scanner-footer-section {
    position: fixed;
    bottom: 0;
    left: 50%;
    text-align: center;
    padding: 20px;
    width: 100%;
    transform: translateX(-50%);
    h6 {
        font-size: 15px;
        font-weight: 600;
        color: var(--text-white);
        margin: 0 0 5px;
    }
    p {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-gray);
        margin: 0;
    }
}
