/* Profile */
.profile-heading-section {
    @include flexbox;
    @include align-items(flex-end);
    @include justify-content(space-between);
    padding: 16px 0;
    .profile-heading-caption {
        h6 {
            font-size: 14px;
            font-weight: bold;
            color: #B5B5C3;
            margin: 0;
            text-transform: uppercase;
        }
    }
    .profile-heading-action {
        .btn {
            background-color: rgba(63, 66, 84, 0.5);
            &:hover,
            &:focus,
            &:active {
                background-color: rgba(63, 66, 84, 0.75);
            }
        }
    }
}
.profile-page {
    padding-bottom: 30px;
}
.profile-header {
    padding: 16px 0 20px;
    background-color: var(--bg-white);
    z-index: 2;
    &__title {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-main);
        @include flexbox;
        @include align-items(center);
        @include justify-content(center);
        > i {
            width: 40px;
            height: 40px;
            padding: 2px;
            border-radius: 50%;
            margin-right: 10px;
            background-color: var(--color-danger);
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            img {
                display: block;
            }
        }
    }
    &.--is-sticky {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
    }
}
.user-guide {
    margin: 35px 0;
    ul {
        margin: 0;
        li {
            font-size: 12px;
            color: var(--text-main);
            list-style-type: none;
            strong {
                font-weight: 500;
                color: var(--color-primary);
            }
        }
    }
}
.user-profile-card {
    align-items: center;
    border-radius: 6px;
    padding: 10px 15px;
    margin-top: 0;
    margin-bottom: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    .card-content {
        padding: 0 0 0 12px;
    }
    .user-profile__label {
        font-size: 14px;
        font-weight: 500;
        color: #B5B5C3;
        margin-top: 0;
        margin-bottom: 4px;
    }
    .user-profile__value {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-dark1);
        line-height: normal;
    }
    &.is-verified {
       &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            background: url('../../img/icons/icon_verify-badge.svg');
            @include base-icon;
       }
       .card-content {
            padding-right: 25px;
       }
    }
}
/* Profile icon section */
.icon-profile__user {
    background: url('../../img/icons/icon-profile__user.svg');
}
.icon-profile__card {
    background: url('../../img/icons/icon-profile__card.svg');
}
.icon-profile__email {
    background: url('../../img/icons/icon-profile__email.svg');
}
.icon-profile__phone {
    background: url('../../img/icons/icon-profile__phone.svg');
}
.icon-profile__calender {
    background: url('../../img/icons/icon-profile__calender.svg');
}
.icon-profile__location {
    background: url('../../img/icons/icon-profile__location.svg');
}
.user-profile__icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    flex-shrink: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
}