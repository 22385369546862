/*app header*/
.app-header {
    position: relative;
    height: 56px;
    line-height: 56px;
    background-color: var(--bg-white);
    z-index: 2;
    box-shadow: none;
    &.app-header__dark {
        background-color: var(--bg-dark);
    }
    &.app-header__gray {
        background-color: #F4F4F4;
    }
    &.has-border {
        &:after {
            content: '';
            width: calc(100% - 40px);
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 20px;
            border-bottom: 1px solid #DBDBDB;
        }
    }
    .nav-wrapper {
        height: 56px;
    }
    .brand-logo {
        width: 100%;
        height: 100%;
        left: 0;
        transform: none;
    }
    .app-header__brand {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        img {
            display: block;
            width: 120px;
            height: auto;
        }
    }
    .sidenav-trigger {
        margin: 0 10px;
    }
    .icon__menu {
        background: url("../../img/icons/icon_menu.svg");
        @include app-icon;
    }
    .app-header__topbar {
        @include flexbox;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        .app-header__topbar-item {
            @include flexbox;
            margin: 0 4px;
        }
        .app-header__topbar-icon {
            width: 30px;
            height: 30px;
            @include flexbox;
            @include align-items(center);
            @include justify-content(center);
            i {
                margin-right: 0;
            }
            .icon__notification {
                background: url("../../img/icons/icon_notification.svg");
                @include base-icon;
            }
            .icon__cart {
                background: url("../../img/icons/icon_shopping-cart.svg");
                @include base-icon;
            }
        }
        .notification-badge {
            position: absolute;
            top: 15px;
            right: 8px;
        }
    }
    .sidenav-arrow {
        margin-left: 10px;
        float: left;
    }
    .material-icons {
        background-repeat: no-repeat;
        text-indent: -999px;
        background-position: center left;
    }
}
.nav-arrow {
    width: 44px;
    height: 44px;
    display: inline-block;
}
.icon__arrow-back {
    background: url("../../img/icons/icon_arrow-back.svg");
    @include app-icon;
}
.icon__arrow-back-white {
    background: url("../../img/icons/icon_arrow-back-white.svg");
    @include app-icon;
}
.notification-badge {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    min-width: 14px;
    height: 14px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: bold;
    padding: 1px;
    z-index: 1;
    color: var(--text-white);
    background-color: var(--bg-alert);
}
@media (min-width: 992px) {
    .app-header {
        .nav-wrapper {
            ul {
                &.hide-on-med-and-down {
                    display: none !important;
                }
            }
        }
    }
}
@media (max-width: 359.98px) {
    .app-header {
        .sidenav-trigger {
            margin: 0 5px;
        }
        .app-header__icon {
            margin-right: 5px;
        }
    }
}